import { Link } from 'react-router-dom'
import { InputGroupAddon, Button, InputGroup, Input } from 'reactstrap'

import logoImg from '../../assets/images/logo.png'
import twitterImg from '../../assets/images/twitter.png'
import telegramImg from '../../assets/images/telegram.png'
import githubImg from '../../assets/images/github-icon.png'

const Footer = () => {
	return (
		<>
			<footer className='inrftr'>
				<div className='container'>
					<div className='row fotpad'>
						<div className='col-xl-3 col-md-12'>
							<img src={logoImg} />
							<p>
								The Quadrix Foundation is a non-profit organization
								headquartered in Zug, Switzerland, dedicated to the
								decentralization, growth, and security of the Quadrix App.
							</p>
							<div className='socdv mb-3'>
								<div className='socdvdt'>
									<div className='socdvdtcnt'>
										<a href='https://twitter.com/QuadrixApp' target='_blank'>
											<img src={twitterImg} />
										</a>
									</div>
									<div className='socdvdtcnt mx-2'>
										<a
											href='https://github.com/QuadrixFoundation/QuadrixApp'
											target='_blank'
										>
											<img src={githubImg} />
										</a>
									</div>
									<div className='socdvdtcnt'>
										<a href='https://t.me/QuadrixApp' target='_blank'>
											<img src={telegramImg} />
										</a>
									</div>
								</div>
							</div>
						</div>
						<div className='col-xl-2 col-4'>
							<h4>About</h4>
							<ul>
								{/* <li>
                                    <a href="#">Contact</a>
                                </li> */}
								<li>
									<a href='#'>Blog</a>
								</li>
								<li>
									<a href='#'>Community</a>
								</li>
							</ul>
						</div>
						<div className='col-xl-2 col-4'>
							<h4>Terms</h4>
							<ul>
								<li>
									<Link to='/terms-of-service'>Terms of Service</Link>
								</li>
								<li>
									<Link to='/trade-mark'>Trademark Guideline</Link>
								</li>
								<li>
									<Link to='/nft-terms'>Trade Room NFT Terms</Link>
								</li>
								<li>
									<Link to='/disclaimer'>Disclaimer</Link>
								</li>
								<li>
									<Link to='/privacy'>Privacy</Link>
								</li>
							</ul>
						</div>
						<div className='col-xl-2 col-4'>
							<h4>Developers</h4>
							<ul>
								{/* <li>
                                    <a href="#">GitLab</a>
                                </li> */}
								<li>
									<a href='#'>Documentation</a>
								</li>
								<li>
									<a href='#'>Bug Bounty</a>
								</li>
								{/* <li>
                                    <a href="#">Careers</a>
                                </li> */}
							</ul>
						</div>
						<div className='col-xl-3 col-md-12'>
							<h3>Join Our Newsletter</h3>
							<InputGroup>
								<Input placeholder='Your email address' />
								<InputGroupAddon addonType='append'>
									<Button color='secondary'>Subscribe</Button>
								</InputGroupAddon>
							</InputGroup>
							<h6>
								Will send you weekly updates for your better finance management.
							</h6>
						</div>
						<div className='col-lg-12'>
							<p className='cprts'>© 2022 Quadrix. All Rights Reserved.</p>
						</div>
					</div>
				</div>
			</footer>
		</>
	)
}

export default Footer
