import { useState } from 'react'
import {
	TabContent,
	TabPane,
	Nav,
	NavItem,
	NavLink,
	Modal,
	ModalBody
} from 'reactstrap'
import { makeStyles } from '@material-ui/core/styles'
import { Stepper, Step, StepLabel } from '@material-ui/core'
import classnames from 'classnames'

import { Header, Footer } from '../../components'
import Banner from './Banner'
import Marquee from './MarqueeSection'
import Services from './Services'
import Stats from './Stats'
import HowItWorks from './HowItWorks'
import Traderoom from './Traderoom'
import Tokenomics from './Tokenomics'
import Lottery from './Lottery'

import mcImg from '../../assets/images/mc.png'
import pciImg from '../../assets/images/pci.png'
import visaImg from '../../assets/images/visa.png'
import frameImg from '../../assets/images/frame.png'
import tickImg from '../../assets/images/tick.png'
import tickmobImg from '../../assets/images/tickmob.png'
import cryptoImg from '../../assets/images/crpto-img.png'
import flgImg from '../../assets/images/flg-icon1.png'
import hlpcrlImg from '../../assets/images/hlp-circle-icon.svg'
import swpImg from '../../assets/images/swp-btn-icon.svg'
import flg2Img from '../../assets/images/flg-icon2.png'
import flg3Img from '../../assets/images/flg-icon3.png'
import editpenImg from '../../assets/images/edit-pen.svg'
import upld1Img from '../../assets/images/upld-img1.png'
import upld3Img from '../../assets/images/upld-img3.png'
import upld2Img from '../../assets/images/upld-img2.png'
import aprvImg from '../../assets/images/approve-icon.svg'
import unaprvImg from '../../assets/images/un-approve-icon.svg'
import phnImg from '../../assets/images/phone-icon.svg'
import sucImg from '../../assets/images/succ-icon.svg'
import bkarrImg from '../../assets/images/back-arrow-btn.svg'

/* Step Wizard Function Start */

import { useForm, FormProvider, useFormContext } from 'react-hook-form'

const useStyles = makeStyles(theme => ({
	button: {
		marginRight: theme.spacing(1)
	}
}))

function getSteps() {
	return ['', '', '', '', '']
}

const StartNowForm = () => {
	const { control } = useFormContext()
	return (
		<>
			<div className='MblSldrItm'>
				<div className='MblSldr'>
					<div className='FiatCrptoImg'>
						<img
							src={cryptoImg}
							className='img-fluid d-block mx-auto'
							alt=''
						/>
					</div>
					<div className='FiatCrptoCnt text-center mb-5'>
						<h4>Fiat Money Crypto Purchase</h4>
						<p>
							The simplest way to buy QBANK tokens with your bank
							card almost instantly
						</p>
					</div>
					<div className='MblSldrBtn pt-3'>
						<button className='btn MblBtn MblBtnPrmry'>
							Start Now
						</button>
					</div>
				</div>
			</div>
		</>
	)
}

const PlaceOrderForm = () => {
	const { control } = useFormContext()
	return (
		<>
			<div className='MblSldrItm'>
				<div className='MblSldr'>
					<div className='PlcYrOrdrHdd text-center mt-4 mb-4 pb-1'>
						<h4>Place your order</h4>
					</div>
					<div className='PayRcvsec mb-5'>
						<div className='PayInptGrp'>
							<label className='FrmLbl'>You Pay</label>
							<div className='input-group'>
								<input
									type='text'
									className='form-control'
									aria-label='Text input with dropdown button'
									placeholder='Amount'
								/>
								<div className='input-group-append dropdown SlctFlgSec'>
									<button
										className='btn SlctFlgDd SlctFlgDdRt dropdown-toggle'
										type='button'
										data-toggle='dropdown'
										aria-expanded='false'
									>
										<span className='mr-3'>
											<img src={flgImg} />
										</span>
										<span className='mr-2'>USD</span>
									</button>
									<div className='dropdown-menu'>
										<div className='dropdown-item'>
											<span className='mr-3'>
												<img src={flgImg} />
											</span>
											<span className='mr-2'>USD</span>
										</div>
										<div className='dropdown-item'>
											<span className='mr-3'>
												<img src={flgImg} />
											</span>
											<span className='mr-2'>USD</span>
										</div>
									</div>
								</div>
							</div>
							<div className='InptBttmSec'>
								<div className=''>
									<small
										id=''
										className='form-text InptInsRd'
									>
										* Minimum pay 50 USD
									</small>
								</div>
								<div className=''>
									<small
										id=''
										className='form-text CoinInsCnt'
									>
										1 QBANK=1 USD
									</small>
									<small
										id=''
										className='form-text CoinInsCnt d-flex'
									>
										Slippage 0.5%{' '}
										<img src={hlpcrlImg} className='ml-2' />
									</small>
								</div>
							</div>
						</div>
						<div className='text-center SwpExcngBtn my-3'>
							<button className='btn'>
								<img src={swpImg} className='img-fluid' />
							</button>
						</div>
						<div className='PayInptGrp mb-4'>
							<label className='FrmLbl'>You Receive</label>
							<div className='input-group'>
								<input
									type='text'
									className='form-control'
									aria-label='Text input with dropdown button'
									placeholder='0.0'
								/>
								<div className='input-group-append dropdown SlctFlgSec'>
									<button
										className='btn SlctFlgDd SlctFlgDdRt dropdown-toggle'
										type='button'
										data-toggle='dropdown'
										aria-expanded='false'
									>
										<span className='mr-2'>
											<img src={flg2Img} />
										</span>
										<span className='mr-1'>QBANK</span>
									</button>
									<div className='dropdown-menu'>
										<div className='dropdown-item'>
											<span className='mr-2'>
												<img src={flgImg} />
											</span>
											<span className='mr-2'>QBANK</span>
										</div>
										<div className='dropdown-item'>
											<span className='mr-2'>
												<img src={flgImg} />
											</span>
											<span className='mr-1'>QBANK</span>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='MblSldrInpt'>
							<div className='form-group mb-0'>
								<label className='FrmLbl'>You Receive</label>
								<input
									type='email'
									className='form-control'
									id=''
									aria-describedby=''
									placeholder='eg: 34xp4vRoCGJymeufFHoCNxv4Twseo'
								/>
							</div>
						</div>
					</div>
					<div className='MblSldrBtn'>
						<button className='btn MblBtn MblBtnPrmry mb-3'>
							Continue
						</button>
						<button className='btn MblBtn MblBtnScndry'>
							Cancel payment
						</button>
					</div>
				</div>
			</div>
		</>
	)
}

const VerifyContactForm = () => {
	const [isActive, setActive] = useState(false)
	const vrfytgl = () => {
		setActive(!isActive)
	}
	const { control } = useFormContext()
	const [activeTab, setActiveTab] = useState('1')
	const toggle = tab => {
		if (activeTab !== tab) setActiveTab(tab)
	}
	const [modal, setModal] = useState(false)
	const toggle1 = () => setModal(!modal)
	return (
		<>
			<div className='MblSldrItm'>
				<div className='MblSldr FlxMdlCntr'>
					<div className='PlcYrOrdrHdd text-center mt-4 mb-4 pb-1'>
						<h4>Verify contact information</h4>
					</div>
					<div className='PayRcvsec mb-5'>
						<div className='MblSldrInpt'>
							<label className='FrmLbl'>By:</label>
							<Nav tabs className='PhnMailTab mb-4'>
								<NavItem>
									<NavLink
										className={classnames({
											active: activeTab === '1'
										})}
										onClick={() => {
											toggle('1')
										}}
									>
										Phone
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										className={classnames({
											active: activeTab === '2'
										})}
										onClick={() => {
											toggle('2')
										}}
									>
										Mail
									</NavLink>
								</NavItem>
							</Nav>
						</div>
						<TabContent activeTab={activeTab}>
							<TabPane tabId='1'>
								<div
									className={
										isActive
											? 'PayInptGrp  PayInptGrpPrpnd BttmModal'
											: 'PayInptGrp  PayInptGrpPrpnd'
									}
								>
									<label className='FrmLbl'>
										Provide Number
									</label>
									<div className='input-group mb-0'>
										<div className='input-group-prepend dropdown SlctFlgSec'>
											<button
												className='btn SlctFlgDd SlctFlgDdLt dropdown-toggle'
												type='button'
												data-toggle='dropdown'
												aria-expanded='false'
											>
												<span className='mr-2'>
													<img src={flg3Img} />
												</span>
												<span className='mr-2'>
													+40
												</span>
											</button>
											<div className='dropdown-menu'>
												<div className='dropdown-item'>
													<span className='mr-2'>
														<img src={flgImg} />
													</span>
													<span className='mr-2'>
														+41
													</span>
												</div>
												<div className='dropdown-item'>
													<span className='mr-2'>
														<img src={flgImg} />
													</span>
													<span className='mr-2'>
														+43
													</span>
												</div>
											</div>
										</div>
										<input
											type='text'
											className='form-control'
											aria-label='Text input with dropdown button'
											placeholder='Your phone number'
										/>
									</div>
									<div className='VrfyCodeSec BttmMdlSec'>
										<div className='VrfyCdHdd text-center my-5'>
											<h4>
												Verify your contact information
											</h4>
										</div>
										<div className='OurIdSec text-center mb-4 pb-1'>
											<h4>
												+40734082241
												<a href='' className=''>
													<img
														src={editpenImg}
														className='ml-2'
													/>
												</a>
											</h4>
											<h4>
												name@gmail.com
												<a href='' className=''>
													<img
														src={editpenImg}
														className='ml-2 d-none'
													/>
												</a>
											</h4>
										</div>
										<div className='EntrCdSec text-center mb-5'>
											<div className='EntrCdCnt mb-4'>
												<p>
													We have sent the code to
													your number. Please enter
													this code below
												</p>
											</div>
											<form
												action=''
												className='EntCdInpt mb-3'
											>
												<input
													className='form-control'
													type='text'
													maxlength='1'
												/>
												<input
													className='form-control'
													type='text'
													maxlength='1'
												/>
												<input
													className='form-control'
													type='text'
													maxlength='1'
												/>
												<input
													className='form-control'
													type='text'
													maxlength='1'
												/>
												<input
													className='form-control'
													type='text'
													maxlength='1'
												/>
												<input
													className='form-control'
													type='text'
													maxlength='1'
												/>
											</form>
											<div className='EntrCdCnt'>
												<p>
													Get new code in{' '}
													<span>0.59</span>
												</p>
											</div>
										</div>
										<div className='MblSldrBtn w-100'>
											<button className='btn MblBtn MblBtnPrmry mb-3'>
												Confirm
											</button>
											<button
												className='btn MblBtn MblBtnScndry'
												data-dismiss='modal'
											>
												Back
											</button>
										</div>
									</div>
								</div>
							</TabPane>
							<TabPane tabId='2'>
								<div className='MblSldrInpt MailInpt'>
									<div className='form-group mb-0'>
										<label className='FrmLbl'>
											Your mail
										</label>
										<input
											type='email'
											className='form-control'
											id=''
											aria-describedby=''
											placeholder='name@gmail.com'
										/>
									</div>
								</div>
							</TabPane>
						</TabContent>
					</div>
					<div className='MblSldrBtn w-100'>
						<a
							className='btn MblBtn MblBtnPrmry mb-3'
							onClick={vrfytgl}
						>
							Continue
						</a>
						<button className='btn MblBtn MblBtnScndry'>
							Back
						</button>
					</div>
				</div>
			</div>
		</>
	)
}

const ProvideCardForm = () => {
	const { control } = useFormContext()
	return (
		<>
			<div className='MblSldrItm'>
				<div className='MblSldr'>
					<div className='PlcYrOrdrHdd text-center mt-3 mb-4'>
						<h4>Provide your card information</h4>
					</div>
					<form>
						<div className='PayRcvsec mb-3'>
							<div className='MblSldrInpt'>
								<div className='form-group mb-2'>
									<label className='FrmLbl'>
										Card Number
									</label>
									<input
										type='email'
										className='form-control'
										id=''
										aria-describedby=''
										placeholder='eg: 4000-1234-5768-9010'
									/>
								</div>
								<div className='form-group mb-2 MblSldrInpt MailInpt'>
									<label className='FrmLbl'>
										Expire Date
									</label>
									<input
										type='email'
										className='form-control'
										id=''
										aria-describedby=''
										placeholder='MM/YY'
									/>
								</div>
								<div className='form-group mb-2'>
									<label className='FrmLbl'>
										Card Holder Name
									</label>
									<input
										type='email'
										className='form-control'
										id=''
										aria-describedby=''
										placeholder='eg: Max Luther'
									/>
								</div>
								<div className='form-group mb-0'>
									<label className='FrmLbl'>CVC</label>
									<input
										type='email'
										className='form-control'
										id=''
										aria-describedby=''
										placeholder='-----'
									/>
								</div>
							</div>
						</div>
						<div className='CkhBoxCnt mb-4'>
							<div className='form-group'>
								<input type='checkbox' id='html' />
								<label for='html'>
									I agree to the Term of Use and Privacy
									Policy
								</label>
							</div>
							<div className='form-group'>
								<input type='checkbox' id='css' />
								<label for='css'>
									I confirm that I am over 18 years old
								</label>
							</div>
							<div className='form-group'>
								<input type='checkbox' id='javascript' />
								<label for='javascript'>
									I confirm that I am not depositing crypto to
									Forex, Gambling, Pharmacy, Steroid websites,
									Antivirus, Dark maket etc.
								</label>
							</div>
						</div>
					</form>
					<div className='MblSldrBtn'>
						<button className='btn MblBtn MblBtnPrmry mb-3'>
							Buy
						</button>
						<button className='btn MblBtn MblBtnScndry'>
							Back
						</button>
					</div>
				</div>
			</div>
		</>
	)
}

const KYC = () => {
	const [isActive, setActive] = useState(false)
	const kyctgl = () => {
		setActive(!isActive)
	}
	const { control } = useFormContext()
	return (
		<>
			<div className='MblSldrItm'>
				<div className={isActive ? 'MblSldr  BttmModal' : 'MblSldr'}>
					<div className='PlcYrOrdrHdd text-center mt-3 mb-4'>
						<h4>Verify (KYC)</h4>
					</div>
					<div className='VrfyKycBg'>
						<div className='VrfyCntHdd text-center mb-4'>
							<h6>Step 1</h6>
							<h4>IDENTITY DOCUMENT</h4>
							<ul className='StpCount'>
								<li className='active'></li>
								<li></li>
								<li></li>
							</ul>
						</div>
						<div className='DocumntTypSec mb-4'>
							<div className='row'>
								<div className='col-7'>
									<div className='form-group mb-2 MailInpt  MblSldrInpt  DcumntSlct'>
										<label className='FrmLbl'>
											Document Type
										</label>
										<select
											className='custom-select form-control'
											id='inputGroupSelect02'
										>
											<option selected>Passport</option>
											<option value='1'>Passport</option>
										</select>
									</div>
								</div>
								<div className='col-5'>
									<div className='form-group mb-2 MblSldrInpt MailInpt'>
										<label className='FrmLbl'>
											Issued Country
										</label>
										<div className='dropdown SlctFlgSec  ContrySlct'>
											<button
												className='btn SlctFlgDd SlctFlgDdRt dropdown-toggle'
												type='button'
												data-toggle='dropdown'
												aria-expanded='false'
												tabindex='0'
											>
												<span className='mr-3'>
													<img src={flgImg} />
												</span>
												<span className='mr-2'>
													USD
												</span>
											</button>
											<div className='dropdown-menu'>
												<div className='dropdown-item'>
													<span className='mr-3'>
														<img src={flgImg} />
													</span>
													<span className='mr-2'>
														USD
													</span>
												</div>
												<div className='dropdown-item'>
													<span className='mr-3'>
														<img src={flgImg} />
													</span>
													<span className='mr-2'>
														USD
													</span>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className='UpldImgCnt mb-4'>
							<p>
								Take a photo of the passport, the photo must be:
							</p>
							<ul className='pl-3'>
								<li>
									<span>Bright and clear</span> (good
									quality),
								</li>
								<li>
									<span>Uncut</span> (all edges of the
									document must be visible).
								</li>
							</ul>
						</div>
						<div className='row mb-4'>
							<div className='col-4'>
								<div className='UpldImgShw'>
									<div className='UpldImg pt-3'>
										<img
											src={upld1Img}
											className='img-fluid d-block mx-auto'
										/>
									</div>
									<div className='ApprvBtn'>
										<img
											src={aprvImg}
											className='img-fluid'
										/>
									</div>
								</div>
							</div>
							<div className='col-4'>
								<div className='UpldImgShw'>
									<div className='UpldImg '>
										<img
											src={upld2Img}
											className='img-fluid d-block mx-auto'
										/>
									</div>
									<div className='ApprvBtn'>
										<img
											src={unaprvImg}
											className='img-fluid'
										/>
									</div>
								</div>
							</div>
							<div className='col-4'>
								<div className='UpldImgShw'>
									<div className='UpldImg pt-3'>
										<img
											src={upld3Img}
											className='img-fluid d-block mx-auto'
										/>
									</div>
									<div className='ApprvBtn'>
										<img
											src={unaprvImg}
											className='img-fluid'
										/>
									</div>
								</div>
							</div>
						</div>
						<div className='FileUpld mb-3'>
							<span>
								<img src={upld1Img} className='img-fluid' />
							</span>
							<span>Upload document</span>
							<input type='file' name='file' />
						</div>
						<div className='CntinuePhon mb-4'>
							<a href='' className=''>
								<img
									src={phnImg}
									className='img-fluid d-inline'
								/>
								Continue on the phone
							</a>
						</div>
						<div className='MblSldrBtn d-flex'>
							<button className='btn MblBtn MblBtnScndry mr-2'>
								Back
							</button>
							<a
								className='btn MblBtn MblBtnPrmry mr-2 BttmMdl'
								onClick={kyctgl}
							>
								Continue
							</a>
						</div>
					</div>
					<div className='VrfyCodeSec OrdrCnform BttmMdlSec'>
						<div className='MdlBrdTp mb-5'></div>
						<div className='OrdrPlcedSec'>
							<div className='OrdrPlcedImg mb-3'>
								<img
									src={sucImg}
									className='img-fluid d-block mx-auto'
								/>
							</div>
							<div className='OrdrPlcedCnt text-center mb-5'>
								<h4>WooHoo!</h4>
								<h6>Your order has been placed</h6>
							</div>
							<div className='OrdplDtls mb-5'>
								<p>Paid: 50 USD</p>
								<p>Total: 0.0026 BTC</p>
								<p>Date: 2022-09-21 06:02:16</p>
								<p>
									Address:
									bc1qxy2kgdygjrsqtzq2n0yrf2493p83kkfjhx0wlh
								</p>
							</div>
						</div>
						<div className='MblSldrBtn w-100'>
							<button
								className='btn MblBtn MblBtnScndry'
								data-dismiss='modal'
							>
								Go Home
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

function getStepContent(step) {
	switch (step) {
		case 0:
			return <StartNowForm />

		case 1:
			return <PlaceOrderForm />
		case 2:
			return <VerifyContactForm />
		case 3:
			return <ProvideCardForm />
		case 4:
			return <KYC />
	}
}

/* Step Wizard Function End*/

const Home = () => {
	const methods = useForm({
		defaultValues: {}
	})

	const [activeTab, setActiveTab] = useState('1')
	const [modal, setModal] = useState(false)
	const [activeStep, setActiveStep] = useState(0)
	const [skippedSteps, setSkippedSteps] = useState([])

	const toggle = tab => {
		if (activeTab !== tab) setActiveTab(tab)
	}
	const toggle1 = () => setModal(!modal)

	const [modal1, setModal1] = useState(false)
	const widtgl = () => setModal1(!modal1)

	const steps = getSteps()

	const isStepOptional = step => {
		return step === 1 || step === 2
	}

	const isStepSkipped = step => {
		return skippedSteps.includes(step)
	}

	const handleNext = data => {
		if (activeStep == steps.length - 1) {
			fetch('https://jsonplaceholder.typicode.com/comments')
				.then(data => data.json())
				.then(res => {
					setActiveStep(activeStep + 1)
				})
		} else {
			setActiveStep(activeStep + 1)
			setSkippedSteps(
				skippedSteps.filter(skipItem => skipItem !== activeStep)
			)
		}
	}

	return (
		<>
			<Header />
			<div className='cntldv'>
				<div className='container-fluid p-0'>
					<Banner />

					<div className='trdbuybg'>
						<Marquee />

						<section className='buytknsec'>
							<div className='container'>
								<div className='row align-items-center'>
									{/* <div className='col-lg-6 order-2p'>
									<div className='MobileSlidrSec'>
										<div className='MblDvice'>
											<div className='MblBody'>
												<div className='MblBdyCnt'>
													<iframe
														src='https://gw.indacoin.io/?partnerId=73'
														height='580px'
														width='385px'
														title='Indacoin widget'
														frameBorder='0'
														allow='accelerometer; autoplay; camera; gyroscope; payment'
													></iframe>
													<FormProvider {...methods}>
														<form
															onSubmit={methods.handleSubmit(
																handleNext
															)}
														>
															{getStepContent(
																activeStep
															)}
														</form>
													</FormProvider>
												</div>
											</div>
										</div>
										<Stepper
											alternativeLabel
											activeStep={activeStep}
										>
											{steps.map((step, index) => {
												const labelProps = {}
												const stepProps = {}
												return (
													<Step
														{...stepProps}
														key={index}
													>
														<StepLabel
															{...labelProps}
														>
															{step}
														</StepLabel>
													</Step>
												)
											})}
										</Stepper>
									</div>
								</div> */}
									{/* <div className='col-lg-6  order-1p'>
									<h3>
										The simplest way to buy QBANK tokens
										with your bank card in minutes.
										<img src={frameImg} className='ml-2' />
									</h3>
									<p>
										You can now buy the tokens listed on{' '}
										<span>Quadrix App</span> via your
										debit/credit card with 5 easy steps. Buy
										your favorite tokens and join the
										community in staking, farming, or
										minting{' '}
										<span>NFTs to earn rewards</span>.
									</p>
									<div className='mltvdacc'>
										<h4>Multiple Cards Are Accepted</h4>
										<div className='mltcdaccdt'>
											<div className='mltcdaccdtcnt'>
												<img src={mcImg} />
											</div>
											<div className='mltcdaccdtcnt'>
												<img src={pciImg} />
											</div>
											<div className='mltcdaccdtcnt'>
												<img src={visaImg} />
											</div>
										</div>
									</div>
									<div className='row trmdv'>
										<div className='col-lg-6'>
											<h5>
												<img
													src={tickImg}
													className='trdrmdtg'
												/>
												<img
													src={tickmobImg}
													className='trdrmdtgmob'
												/>
												Top class security
											</h5>
											<h5>
												<img
													src={tickImg}
													className='trdrmdtg'
												/>
												<img
													src={tickmobImg}
													className='trdrmdtgmob'
												/>
												Lighting Fast Transactions
											</h5>
										</div>
										<div className='col-lg-6'>
											<h5>
												<img
													src={tickImg}
													className='trdrmdtg'
												/>
												<img
													src={tickmobImg}
													className='trdrmdtgmob'
												/>
												Accepted countries 15+
											</h5>
											<h5>
												<img
													src={tickImg}
													className='trdrmdtg'
												/>
												<img
													src={tickmobImg}
													className='trdrmdtgmob'
												/>
												Multiple Device Complient
											</h5>
										</div>
									</div>
									<div className="trmscntdv">
                                                <label className="chkcontainer">I agree to the Privacy and Terms of use
                                                    <input type="checkbox"  />
                                                    <span className="checkmark"></span>
                                                </label>
                                                <label className="chkcontainer">I confirm that I am not depositing to Forex, Gambling, Pharmacy, Steroid websites, Dark market etc,and I am fully aware of fine applied to my card if I create such transaction. Failure to company will force an investigation by the authorities
                                                    <input type="checkbox"  />
                                                    <span className="checkmark"></span>
                                                </label>
                                            </div>
									<button className='btn primary-btn w-162-h-65'>
										Buy Now
									</button>
								</div> */}
									<div className='col-lg-12  order-1p'>
										<h3>
											The simplest way to buy QBANK tokens
											with your bank card in minutes.
											<img
												src={frameImg}
												className='ml-2'
											/>
										</h3>
										<p>
											You can now buy the tokens listed on{' '}
											<span>Quadrix App</span> via your
											debit/credit card with 5 easy steps.
											Buy your favorite tokens and join
											the community in staking, farming,
											or minting{' '}
											<span>NFTs to earn rewards</span>.
										</p>
										<div className='buytkninrsec'>
											<div className='row trmdv justify-content-center mx-0'>
												<h5>
													<img
														src={tickmobImg}
														className='trdrmdtgmob'
													/>
													Top class security
												</h5>
												<h5>
													<img
														src={tickmobImg}
														className='trdrmdtgmob'
													/>
													Lighting Fast Transactions
												</h5>
												<h5>
													<img
														src={tickmobImg}
														className='trdrmdtgmob'
													/>
													Accepted countries 15+
												</h5>
												<h5>
													<img
														src={tickmobImg}
														className='trdrmdtgmob'
													/>
													Multiple Device Complient
												</h5>
												<h5>
													<img
														src={tickmobImg}
														className='trdrmdtgmob'
													/>
													Multiple Cards Are Accepted
												</h5>
											</div>
											{/* <div className="mltvdacc">
                                                    <h4>Multiple Cards Are Accepted</h4>
                                                    <div className="mltcdaccdt">
                                                        <div className="mltcdaccdtcnt">
                                                            <img src={mcImg}/>
                                                        </div>
                                                        <div className="mltcdaccdtcnt">
                                                            <img src={pciImg}/>
                                                        </div>
                                                        <div className="mltcdaccdtcnt">
                                                            <img src={visaImg}/>
                                                        </div>
                                                    </div>
                                                </div>                                                */}
											<div className='trmscntdv'>
												<label className='chkcontainer1'>
													{' '}
													<div className='trmscntdvimg'>
														<img src={mcImg} />
													</div>
													<input
														type='radio'
														checked
													/>
													<span className='checkmark1'></span>
												</label>
												<label className='chkcontainer1'>
													<div className='trmscntdvimg'>
														<img src={pciImg} />
													</div>
													<input
														type='radio'
														checked
													/>
													<span className='checkmark1'></span>
												</label>
												<label className='chkcontainer1'>
													<div className='trmscntdvimg'>
														<img src={visaImg} />
													</div>
													<input
														type='radio'
														checked
													/>
													<span className='checkmark1'></span>
												</label>
											</div>
											<button
												className='btn primary-btn w-162-h-65'
												onClick={widtgl}
											>
												Buy Now
											</button>
										</div>
									</div>
								</div>
							</div>
						</section>
					</div>

					<Services />

					<Stats />

					<HowItWorks />

					<Traderoom />

					<Tokenomics />

					<Lottery />
				</div>
			</div>
			<Footer />
			<Modal
				isOpen={modal1}
				toggle={widtgl}
				className='CnctWlltMdl MngTknMdl'
			>
				<ModalBody>
					<div class='MdlHdr mb-4'>
						<h5 class=''>
							<img
								style={{ cursor: 'pointer' }}
								src={bkarrImg}
								class='mr-3'
								onClick={widtgl}
							/>
						</h5>
						<button
							type='button'
							class='close MdlClosBtn'
							onClick={widtgl}
						>
							<span aria-hidden='true'>&times;</span>
						</button>
					</div>
					<div class='MngTknMdlTb'>
						<iframe
							src='https://gw.indacoin.io/?cur_to=qbank-eth-btc-usdt-busd-bnb&partnerId=73'
							height='580px'
							width='100%'
							title='Indacoin widget'
							frameBorder='0'
							allow='accelerometer; autoplay; camera; gyroscope; payment'     
						></iframe>
					</div>
				</ModalBody>
			</Modal>
		</>
	)
}

export default Home
