import ReactApexChart from 'react-apexcharts'

import slcttkn1Img from '../../assets/images/slct-tkn-icon1.svg'
import chrttknImg from '../../assets/images/chrt-tkn-icon2.png'
import chrtrptImg from '../../assets/images/chrt-repaeat-icon.svg'
import amtarImg from '../../assets/images/amnt-arrw.svg'
import lvchrtImg from '../../assets/images/lv-chrt-img.png'

export const LiveChart = () => {
	const chartInfo = {
		series: [
			{
				name: 'Live Chart',
				data: [
					{
						x: new Date('2018-02-12').getTime(),
						y: 12
					},
					{
						x: new Date('2018-02-13').getTime(),
						y: 16
					},
					{
						x: new Date('2018-02-14').getTime(),
						y: 19
					},
					{
						x: new Date('2018-02-15').getTime(),
						y: 120
					},
					{
						x: new Date('2018-02-16').getTime(),
						y: 66
					},
					{
						x: new Date('2018-02-17').getTime(),
						y: 12
					},
					{
						x: new Date('2018-02-18').getTime(),
						y: 25
					},
					{
						x: new Date('2018-02-19').getTime(),
						y: 16
					},
					{
						x: new Date('2018-02-20').getTime(),
						y: 10
					},
					{
						x: new Date('2018-02-21').getTime(),
						y: 100
					}
				]
			}
		],
		options: {
			chart: {
				type: 'area',
				stacked: false,
				height: 350,
				zoom: {
					type: 'x',
					enabled: true,
					autoScaleYaxis: true
				},
				toolbar: {
					show: false
					// autoSelected: 'zoom'
				}
			},
			colors: ['#258886'],
			dataLabels: {
				enabled: false
			},
			markers: {
				size: 0
			},
			// title: {
			// 	text: 'Stock Price Movement',
			// 	align: 'left'
			// },
			fill: {
				type: 'gradient',
				gradient: {
					shadeIntensity: 1,
					inverseColors: false,
					opacityFrom: 0.5,
					opacityTo: 0,
					stops: [0, 90, 100]
				}
			},
			yaxis: {
				labels: {
					formatter: function (val) {
						return ''
					}
				}
			},
			xaxis: {
				type: 'datetime'
			},
			tooltip: {
				shared: false
				// y: {
				// 	formatter: function (val) {
				// 		return (val / 1000000).toFixed(0)
				// 	}
				// }
			},
			grid: {
				show: false
			}
		}
	}
	return (
		<>
			<div className='col-lg-7'>
				<div className='CmmnWhtBg'>
					<div className='LvChrtSec pb-2'>
						<div className='SwpHdd mb-4'>
							<h4>Live Chart</h4>
						</div>
						<div className='ChrtHddSec'>
							<div className='ChrtTknSec d-flex'>
								<div className='ChrtTknImgSec d-flex'>
									<div className='ChrtTknImg ChrtTknImg1'>
										<img src={slcttkn1Img} className='img-fluid' />
									</div>
									<div className='ChrtTknImg ChrtTknImg2'>
										<img src={chrttknImg} className='img-fluid' />
									</div>
								</div>
								<div className='ChrtTknCnt'>
									<h4>
										QDRX/USDT
										<a href=''>
											<img src={chrtrptImg} className='img-fluid ml-2' />
										</a>
									</h4>
								</div>
							</div>

							<div className='ChrtYrsClc'>
								<ul>
									<li>
										<a href=''>1H</a>
									</li>
									<li>
										<a href='' className='active'>
											4H
										</a>
									</li>
									<li>
										<a href=''>1D</a>
									</li>
									<li>
										<a href=''>1W</a>
									</li>
									<li>
										<a href=''>1M</a>
									</li>
									<li>
										<a href=''>6M</a>
									</li>
								</ul>
							</div>
						</div>
						<div className='ChrtAmnt'>
							<h4>
								10.392 USDT
								<span className='mx-2'>0.06%</span>
								<img src={amtarImg} />
							</h4>
						</div>
					</div>
					<div className='LvChrtImg pb-4'>
						{/* <img src={lvchrtImg} className='img-fluid d-block mx-auto' /> */}
						<div id='chart'>
							<ReactApexChart
								options={chartInfo.options}
								series={chartInfo.series}
								type='area'
								height={350}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}
