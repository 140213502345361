import React, { useState, useEffect } from 'react'

import {
	TabContent,
	TabPane,
	Nav,
	NavItem,
	NavLink,
	Modal,
	ModalBody
} from 'reactstrap'
import classnames from 'classnames'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Collapsible from 'react-collapsible'


import sttng1Img from '../../assets/images/sttng-icon1.svg'
import sttng2Img from '../../assets/images/sttng-icon2.svg'
import bkarrImg from '../../assets/images/back-arrow-btn.svg'
import swpwltImg from '../../assets/images/swp-wallet.svg'
import tknrfrImg from '../../assets/images/tkn-rfrs-icon.svg'
import excbtnImg from '../../assets/images/exchnge-btn.svg'
import slcttknImg from '../../assets/images/slct-tkn-arrw.png'
import slcttkn1Img from '../../assets/images/slct-tkn-icon1.svg'
import infoiconImg from '../../assets/images/info-icon.svg'
import addinfoiconImg from '../../assets/images/adv-info-icon.svg'

import slceqlImg from '../../assets/images/slc-equal-icon.svg'
import slcttknicon3 from '../../assets/images/slct-tkn-icon3.png'
import slcttknicon1 from '../../assets/images/slct-tkn-icon1.png'
import slcttknicon2 from '../../assets/images/slct-tkn-icon2.png'
import slcttknicon4 from '../../assets/images/slct-tkn-icon4.png'
import slcttknicon5 from '../../assets/images/slct-tkn-icon5.png'
import slcttknicon6 from '../../assets/images/slct-tkn-icon6.png'
import slcttknicon7 from '../../assets/images/slct-tkn-icon7.png'
import slcttknicon8 from '../../assets/images/slct-tkn-icon8.png'
import pairtkn1 from '../../assets/images/pair-tkn-icon1.png'
import pairtkn2 from '../../assets/images/pair-tkn-icon2.png'
import pairtkn3 from '../../assets/images/pair-tkn-icon3.png'
import pairtkn4 from '../../assets/images/pair-tkn-icon4.png'
import pairtkn5 from '../../assets/images/pair-tkn-icon5.png'
import pairtkn6 from '../../assets/images/pair-tkn-icon6.png'
import pairtkn7 from '../../assets/images/pair-tkn-icon7.png'
import mngtknedit from '../../assets/images/mng-tkn-edit.svg'
import mngcopyImg from '../../assets/images/mng-cpy-icon.svg'

import {
	InnerHeader,
	InnerFooter,
	modalService,
	ConnectWalletModal
} from '../../components'
import useWeb3 from '../../web3/hooks/useWeb3'
import { LiveChart } from './LiveChart'
import { UpcomingInventory } from './UpcomingInventory'

const Swap = () => {
	const [isOpen, setIsOpen] = useState(false)
	const toggle = () => setIsOpen(!isOpen)

	const [isActive, setActive] = useState(false)
	const AdvancedSettingsToggle = () => {
		if (isActive1) setActive1(false)
		setActive(!isActive)
	}

	const [isActive1, setActive1] = useState(false)
	const DisplaySettingsToggle = () => {
		if (isActive) setActive(false)
		setActive1(!isActive1)
	}

	const [modal1, setModal1] = useState(false)
	const slttkntgl = () => setModal1(!modal1)

	const [modal2, setModal2] = useState(false)
	const mngtkntgl = () => setModal2(!modal2)

	const [activeTab, setActiveTab] = useState('1')
	const mngtabtgl = tab => {
		if (activeTab !== tab) setActiveTab(tab)
	}

	const [activeTab1, setActiveTab1] = useState('1')
	const allfavtgl = tab => {
		if (activeTab1 !== tab) setActiveTab1(tab)
	}

	const { active, account } = useWeb3()

	const [displaySettingsStatus, setDisplaySettingsStatus] = useState(() => {
		let inventory = localStorage.getItem('inventory')
		let chart = localStorage.getItem('chart')
		inventory = inventory ? JSON.parse(inventory) : false
		chart = chart ? JSON.parse(chart) : false
		return { inventory, chart }
	})

	const { inventory, chart } = displaySettingsStatus

	const DisplaySettings = ({ target: { checked } }, setting) => {
		setDisplaySettingsStatus(prev => ({ ...prev, [setting]: checked }))
		localStorage.setItem(setting, checked)
	}

	return (
		<>
			<div className='cmndv'>
				<InnerHeader />
				<div className='cntldvinr'>
					<div className='SwpMainPg'>
						<div className='container container-1180'>
							<div className='row'>
								<div className='col-lg-6 mx-auto'>
									<ul className='SwpLqtTitl'>
										<li>
											<a href='#' className='active'>
												Swap
											</a>
										</li>
										<li>
											<a href='#'>Liquidity</a>
										</li>
									</ul>
								</div>
								<div className='col-12 mb-3'>
									{inventory && <UpcomingInventory />}
								</div>
							</div>
							<div
								className={`row ${
									!chart &&
									'align-items-center justify-content-between'
								}`}
							>
								<div className='col-lg-5 mb-3  mx-auto'>
									<div className='CmmnWhtBg SwpSec mb-3'>
										<div className='SwpHddSec mb-4'>
											<div className='SwpHdd'>
												<h4>Swap</h4>
											</div>
											<div className='SwpSttng d-flex'>
												<div
													className={
														isActive
															? 'AdvSttSec AdvSttSec_show'
															: 'AdvSttSec'
													}
												>
													<div
														className='tool mr-2'
														data-tip='Advanced Settings'
														onClick={
															AdvancedSettingsToggle
														}
													>
														<a className='text'>
															<img
																src={sttng1Img}
																className='img-fluid'
															/>
														</a>
													</div>
													<div className='AdvnSttSec d-none'>
														<div className='MdlHdr mb-5'>
															<h5 className=''>
																<a
																	onClick={
																		AdvancedSettingsToggle
																	}
																>
																	<img
																		src={
																			bkarrImg
																		}
																		className='mr-3'
																	/>
																</a>
																Advanced
																Settings
															</h5>
														</div>
														<div className='Tlrn pb-1'>
															<div className='TlrncSec'>
																<h4>
																	Slippage
																	Tolerance{' '}
																	<a
																		href=''
																		className='tool TllTpW227'
																		data-tip='Transaction will revert if there is an adverse rate change that is higher than this %'
																	>
																		<img
																			src={
																				addinfoiconImg
																			}
																			className='img-fluid ml-2 text'
																		/>
																	</a>
																</h4>
															</div>
															<div className='TlrncSecBtnSec'>
																<ul>
																	<li>
																		<button className='btn TlrncSecBtn ml-0'>
																			0.05%
																		</button>
																	</li>
																	<li>
																		<button className='btn TlrncSecBtn'>
																			0.1%
																		</button>
																	</li>
																	<li>
																		<button className='btn TlrncSecBtn'>
																			0.5%
																		</button>
																	</li>
																	<li>
																		<button className='btn TlrncSecBtn'>
																			1%
																		</button>
																	</li>
																	<li>
																		<button className='btn TlrncSecBtn'>
																			Custom
																			%
																		</button>
																	</li>
																</ul>
															</div>
														</div>
														<div className='GasPrc pb-2'>
															<div className='TlrncSec'>
																<h4>
																	Gas Price
																</h4>
															</div>
															<div className='TlrncSecBtnSec GsprcBtns'>
																<ul>
																	<li>
																		<button className='btn TlrncSecBtn ml-0'>
																			Normal
																		</button>
																	</li>
																	<li>
																		<button className='btn TlrncSecBtn'>
																			Fast
																		</button>
																	</li>
																	<li>
																		<button className='btn TlrncSecBtn'>
																			Instant
																		</button>
																	</li>
																</ul>
															</div>
														</div>
														<div className='TimeLmt'>
															<div className='TlrncSec'>
																<h4>
																	Transaction
																	Time Limit
																	<a
																		href=''
																		className='tool TllTpW227'
																		data-tip='Transaction will revert if it is pending for longer than the indicated time'
																	>
																		<img
																			src={
																				addinfoiconImg
																			}
																			className='img-fluid ml-2 text'
																		/>
																	</a>
																</h4>
															</div>
															<div className='TlrncSecBtnSec TimeLmtBtns'>
																<ul>
																	<li>
																		<button className='btn TlrncSecBtn ml-0'>
																			30
																			mins
																		</button>
																	</li>
																</ul>
															</div>
														</div>
													</div>
												</div>
												<div
													className={
														isActive1
															? 'DsplStSec DsplStSec_show'
															: 'DsplStSec'
													}
												>
													<div
														className='tool ml-1'
														data-tip='Display Settings'
													>
														<a
															className='text'
															onClick={
																DisplaySettingsToggle
															}
														>
															<img
																src={sttng2Img}
																className='img-fluid'
															/>
														</a>
													</div>
													<div className='DsplySett DsplySettBg d-none'>
														<div className='MdlHdr mb-4 pb-1'>
															<h5 className=''>
																<a
																	onClick={
																		DisplaySettingsToggle
																	}
																>
																	<img
																		src={
																			bkarrImg
																		}
																		className='mr-3'
																	/>
																</a>
																Display Settings
															</h5>
														</div>
														<div className='DsplyCnt d-flex justify-content-between align-items-center mb-3'>
															<div className='TknNme'>
																<p>
																	Upcoming
																	Inventory
																</p>
															</div>
															<div className='AllTknsCntRt OnOffSwitch'>
																<label className='switch'>
																	<input
																		type='checkbox'
																		className='switch-input'
																		onChange={e =>
																			DisplaySettings(
																				e,
																				'inventory'
																			)
																		}
																		checked={
																			inventory
																		}
																	/>
																	<span className='switch-handle'></span>
																</label>
															</div>
														</div>
														<div className='DsplyCnt d-flex justify-content-between align-items-center'>
															<div className='TknNme'>
																<p>
																	Live chart
																</p>
															</div>
															<div className='AllTknsCntRt OnOffSwitch'>
																<label className='switch'>
																	<input
																		type='checkbox'
																		className='switch-input'
																		checked={
																			chart
																		}
																		onChange={e =>
																			DisplaySettings(
																				e,
																				'chart'
																			)
																		}
																	/>
																	<span className='switch-handle'></span>
																</label>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div className='SellSec'>
											<div className='SwpFlx mb-2 pb-1'>
												<div className='YrSllCnt'>
													<p>You sell</p>
												</div>
												<div className='WlltAmnt d-flex align-items-center'>
													<div className='WlltAmntSec mr-2'>
														<h4>
															<img
																src={swpwltImg}
																className='mr-2 img-fluid'
															/>
															2000 QDRX
														</h4>
													</div>
													<div className='WlltAmntBtn '>
														<button className='btn WlltAmntMxBtn'>
															MAX
														</button>
													</div>
												</div>
											</div>
											<div className='SwpFlx mb-2 pb-1'>
												<div className='TknVlue'>
													<h4>100.12</h4>
												</div>
												<div className='SlctTknBtnSec'>
													<button
														className='btn SlctTknBtn'
														onClick={slttkntgl}
													>
														<img
															src={slcttkn1Img}
															className='mr-2 img-fluid'
														/>
														QDRX{' '}
														<img
															src={slcttknImg}
															className='ml-2 img-fluid'
														/>
													</button>
												</div>
											</div>
											<div className='SwpFlx'>
												<div className='YrSllCnt'>
													<p>$ 100.12</p>
												</div>
												<div className='YrSllCnt'>
													<p>Quadrix Coin</p>
												</div>
											</div>
										</div>
										<div className='TknDtls'>
											<div className='UsdBtcAmt mt-1'>
												<h4>
													<a href=''>
														<img
															src={tknrfrImg}
															className='mr-2'
														/>
													</a>
													<span>1USDT</span> ={' '}
													<span>0.000049BTC</span>{' '}
												</h4>
											</div>
											<div className='ExchngeBtnSec'>
												<button className='btn ExchngeBtn'>
													<img
														src={excbtnImg}
														className='img-fluid'
													/>
												</button>
											</div>
										</div>
										<div className='SellSec GetSlctTkn'>
											<div className='SwpFlx mb-2 pb-1'>
												<div className='YrSllCnt'>
													<p>You get</p>
												</div>
												<div className='WlltAmnt d-flex align-items-center'>
													<div className='WlltAmntSec YouGtCnt'>
														<h4>
															<img
																src={swpwltImg}
																className='mr-2 img-fluid'
															/>
															0.00
														</h4>
													</div>
												</div>
											</div>
											<div className='SwpFlx mb-2 pb-1'>
												<div className='TknVlue'>
													<h4>0.0</h4>
												</div>
												<div className='SlctTknBtnSec'>
													<button
														className='btn SlctTknBtn pl-3'
														onClick={slttkntgl}
													>
														Select token{' '}
														<img
															src={slcttknImg}
															className='ml-2 img-fluid'
														/>
													</button>
												</div>
											</div>
											<div className='SwpFlx'>
												<div className='YrSllCnt'>
													<p>$ 0.0</p>
												</div>
												<div className='YrSllCnt'>
													<p>Token Name</p>
												</div>
											</div>
										</div>
										<div className='UsdBtcAmt mt-1'>
											<h4>
												Max slippage: <span>0.5%</span>{' '}
											</h4>
										</div>
										<div className='CnctWlltBtnSec mt-4 pt-1'>
											<button
												className='btn CnctWlltBtn w-100'
												onClick={
													active
														? () => {}
														: () =>
																modalService.sendMessage(
																	'open'
																)
												}
											>
												{active
													? 'Swap'
													: 'Connect Wallet'}
											</button>
										</div>
										<div className='CnctWlltBtnSec mt-4 pt-1 d-none'>
											<button className='btn CnctWlltBtn w-100'>
												Swap
											</button>
										</div>
										<div className='CnctWlltBtnSec mt-4 pt-1 d-none'>
											<button className='btn CnctWlltBtn w-100 EnsuffBlBtn'>
												Ensufficient Token Balance
											</button>
										</div>
									</div>
									<div className='CmmnWhtBg MorInfr'>
										<div className='CllpseSec'>
											<div class='card'>
												<Collapsible trigger='More Information'>
													<div class='MrInfBody mt-3'>
														<div class='MorInfo'>
															<p>
																Expected Output
															</p>
															<h4>
																0.000 Coin{' '}
																<a
																	href=''
																	class='tool TllTpW227 TllTpW195'
																	data-tip='Minimum amunt you will get otherwise it will revert'
																>
																	<img
																		src={
																			infoiconImg
																		}
																		class='ml-2 img-fluid text'
																	/>
																</a>
															</h4>
														</div>
														<div class='MorInfo'>
															<p>
																Minimum received
																after slippage
																(0.5% )
															</p>
															<h4>
																0%
																<a
																	href=''
																	class='tool TllTpW227'
																	data-tip='Transaction will revert if there is an adverse rate change that is higher than this %'
																>
																	<img
																		src={
																			infoiconImg
																		}
																		class='ml-2 img-fluid text'
																	/>
																</a>
															</h4>
														</div>
														<div class='MorInfo'>
															<p>Price Imapact</p>
															<h4>
																0.000 Coin{' '}
																<a
																	href=''
																	class='tool TllTpW227 TllTpW195'
																	data-tip='Estimated change in price due to tha size of your transaction'
																>
																	<img
																		src={
																			infoiconImg
																		}
																		class='ml-2 img-fluid text'
																	/>
																</a>
															</h4>
														</div>
														<div class='MorInfo'>
															<p>Network Fee</p>
															<h4>
																$0.00
																<a
																	href=''
																	class='tool TllTpW227 TllTpW195'
																	data-tip='Estimated network fee for your transaction '
																>
																	<img
																		src={
																			infoiconImg
																		}
																		class='ml-2 img-fluid text'
																	/>
																</a>
															</h4>
														</div>
													</div>
												</Collapsible>
											</div>
										</div>
									</div>
								</div>
								{chart && <LiveChart />}
							</div>
						</div>
					</div>
				</div>

				<InnerFooter />
			</div>
			<div class=''>
				<ConnectWalletModal />
				<Modal
					isOpen={modal1}
					toggle={slttkntgl}
					className='CnctWlltMdl'
				>
					<ModalBody>
						<div class='MdlHdr mb-4 BrdBttm'>
							<h5 class='' id='staticBackdropLabel'>
								Select a token
							</h5>
							<button
								type='button'
								class='close MdlClosBtn'
								onClick={slttkntgl}
							>
								<span aria-hidden='true'>&times;</span>
							</button>
						</div>
						<div class='MdlMainCnt'>
							<div class='SerchInpt'>
								<div class='form-group'>
									<input
										type='text'
										class='form-control'
										id=''
										aria-describedby='emailHelp'
										placeholder='Search token name, symbol or address'
									/>
								</div>
							</div>
							<div class='CmmnlyPair mb-3'>
								<div class='CmmnlyPairCnt mb-3'>
									<p>
										Commonly paired tokens
										<img
											src={infoiconImg}
											class='img-fluid ml-2'
										/>
									</p>
								</div>
								<div class='PairdTknDtls'>
									<button class='btn PairdTknBtn'>
										<img
											src={pairtkn1}
											class='img-fluid mr-2'
										/>
										USDT
									</button>
									<button class='btn PairdTknBtn'>
										<img
											src={pairtkn2}
											class='img-fluid mr-2'
										/>
										BUSD
									</button>
									<button class='btn PairdTknBtn'>
										<img
											src={pairtkn3}
											class='img-fluid mr-2'
										/>
										USDC
									</button>
									<button class='btn PairdTknBtn'>
										<img
											src={pairtkn4}
											class='img-fluid mr-2'
										/>
										WETH
									</button>
									<button class='btn PairdTknBtn'>
										<img
											src={pairtkn5}
											class='img-fluid mr-2'
										/>
										DAI
									</button>
									<button class='btn PairdTknBtn'>
										<img
											src={pairtkn6}
											class='img-fluid mr-2'
										/>
										BCH
									</button>
									<button class='btn PairdTknBtn'>
										<img
											src={pairtkn7}
											class='img-fluid mr-2'
										/>
										ETH
									</button>
								</div>
							</div>
						</div>
						<div class='FvAllTbSec'>
							{/* <ul class="nav nav-tabs" id="myTab" role="tablist">
                           <li class="nav-item" role="presentation">
                              <button class="nav-link active" id="home-tab" data-toggle="tab" data-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">All</button>
                           </li>
                           <li class="nav-item" role="presentation">
                              <button class="nav-link" id="profile-tab" data-toggle="tab" data-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Favourites</button>
                           </li>
                        </ul> */}
							<Nav tabs>
								<NavItem>
									<NavLink
										className={classnames({
											active: activeTab1 === '1'
										})}
										onClick={() => {
											allfavtgl('1')
										}}
									>
										All
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										className={classnames({
											active: activeTab1 === '2'
										})}
										onClick={() => {
											allfavtgl('2')
										}}
									>
										Favourites
									</NavLink>
								</NavItem>
							</Nav>
							<TabContent activeTab={activeTab1}>
								<TabPane tabId='1'>
									<div class='AllTknsSec SlctTknHgt'>
										<div class='AllTknsCnt'>
											<div class='AllTknsCntLft'>
												<div class='FvStr mr-3'>
													<span class='fa fa-star checked'></span>
												</div>
												<div class='d-flex'>
													<div class='TknImg'>
														<img
															src={slcttknicon1}
															class='img-fluid mr-2'
														/>
													</div>
													<div class='TknNme'>
														<h4>STMX</h4>
														<p>StormX</p>
													</div>
												</div>
											</div>
											<div class='AllTknsCntRt'>
												<div class='TknNme text-right '>
													<h4>0</h4>
													<p>
														<img
															src={slceqlImg}
															class='img-fluid mr-2'
														/>
														$ 0.00
													</p>
												</div>
											</div>
										</div>
										<div class='AllTknsCnt'>
											<div class='AllTknsCntLft'>
												<div class='FvStr mr-3'>
													<span class='fa fa-star checked'></span>
												</div>
												<div class='d-flex'>
													<div class='TknImg'>
														<img
															src={slcttknicon2}
															class='img-fluid mr-2'
														/>
													</div>
													<div class='TknNme'>
														<h4>USDT</h4>
														<p>Tether USD</p>
													</div>
												</div>
											</div>
											<div class='AllTknsCntRt'>
												<div class='TknNme text-right '>
													<h4>400</h4>
													<p>
														<img
															src={slceqlImg}
															class='img-fluid mr-2'
														/>
														$ 400.00
													</p>
												</div>
											</div>
										</div>
										<div class='AllTknsCnt'>
											<div class='AllTknsCntLft'>
												<div class='FvStr mr-3'>
													<span class='fa fa-star-o checked'></span>
												</div>
												<div class='d-flex'>
													<div class='TknImg'>
														<img
															src={slcttknicon3}
															class='img-fluid mr-2'
														/>
													</div>
													<div class='TknNme'>
														<h4>AAVE</h4>
														<p>Aave</p>
													</div>
												</div>
											</div>
											<div class='AllTknsCntRt'>
												<div class='TknNme text-right '>
													<h4>0</h4>
													<p>
														<img
															src={slceqlImg}
															class='img-fluid mr-2'
														/>
														$ 0.00
													</p>
												</div>
											</div>
										</div>
										<div class='AllTknsCnt'>
											<div class='AllTknsCntLft'>
												<div class='FvStr mr-3'>
													<span class='fa fa-star-o checked'></span>
												</div>
												<div class='d-flex'>
													<div class='TknImg'>
														<img
															src={slcttknicon4}
															class='img-fluid mr-2'
														/>
													</div>
													<div class='TknNme'>
														<h4>AMP</h4>
														<p>Amp Coin</p>
													</div>
												</div>
											</div>
											<div class='AllTknsCntRt'>
												<div class='TknNme text-right '>
													<h4>0</h4>
													<p>
														<img
															src={slceqlImg}
															class='img-fluid mr-2'
														/>
														$ 0.00
													</p>
												</div>
											</div>
										</div>
										<div class='AllTknsCnt'>
											<div class='AllTknsCntLft'>
												<div class='FvStr mr-3'>
													<span class='fa fa-star-o checked'></span>
												</div>
												<div class='d-flex'>
													<div class='TknImg'>
														<img
															src={slcttknicon5}
															class='img-fluid mr-2'
														/>
													</div>
													<div class='TknNme'>
														<h4>APE</h4>
														<p>Ape Coin</p>
													</div>
												</div>
											</div>
											<div class='AllTknsCntRt'>
												<div class='TknNme text-right '>
													<h4>0</h4>
													<p>
														<img
															src={slceqlImg}
															class='img-fluid mr-2'
														/>
														$ 0.00
													</p>
												</div>
											</div>
										</div>
										<div class='AllTknsCnt'>
											<div class='AllTknsCntLft'>
												<div class='FvStr mr-3'>
													<span class='fa fa-star-o checked'></span>
												</div>
												<div class='d-flex'>
													<div class='TknImg'>
														<img
															src={slcttknicon6}
															class='img-fluid mr-2'
														/>
													</div>
													<div class='TknNme'>
														<h4>QDRX</h4>
														<p>Quadrix Token</p>
													</div>
												</div>
											</div>
											<div class='AllTknsCntRt'>
												<div class='TknNme text-right '>
													<h4>0</h4>
													<p>
														<img
															src={slceqlImg}
															class='img-fluid mr-2'
														/>
														$ 0.00
													</p>
												</div>
											</div>
										</div>
										<div class='AllTknsCnt'>
											<div class='AllTknsCntLft'>
												<div class='FvStr mr-3'>
													<span class='fa fa-star-o checked'></span>
												</div>
												<div class='d-flex'>
													<div class='TknImg'>
														<img
															src={slcttknicon7}
															class='img-fluid mr-2'
														/>
													</div>
													<div class='TknNme'>
														<h4>BNT</h4>
														<p>
															Banchor Network
															Token
														</p>
													</div>
												</div>
											</div>
											<div class='AllTknsCntRt'>
												<div class='TknNme text-right '>
													<h4>0</h4>
													<p>
														<img
															src={slceqlImg}
															class='img-fluid mr-2'
														/>
														$ 0.00
													</p>
												</div>
											</div>
										</div>
									</div>
								</TabPane>
								<TabPane tabId='2'>
									<div class='AllTknsSec'>
										<div class='AllTknsCnt'>
											<div class='AllTknsCntLft'>
												<div class='FvStr mr-3'>
													<span class='fa fa-star checked'></span>
												</div>
												<div class='d-flex'>
													<div class='TknImg'>
														<img
															src={slcttknicon1}
															class='img-fluid mr-2'
														/>
													</div>
													<div class='TknNme'>
														<h4>STMX</h4>
														<p>StormX</p>
													</div>
												</div>
											</div>
											<div class='AllTknsCntRt'>
												<div class='TknNme text-right '>
													<h4>0</h4>
													<p>
														<img
															src={slceqlImg}
															class='img-fluid mr-2'
														/>
														$ 0.00
													</p>
												</div>
											</div>
										</div>
										<div class='AllTknsCnt'>
											<div class='AllTknsCntLft'>
												<div class='FvStr mr-3'>
													<span class='fa fa-star checked'></span>
												</div>
												<div class='d-flex'>
													<div class='TknImg'>
														<img
															src={slcttknicon2}
															class='img-fluid mr-2'
														/>
													</div>
													<div class='TknNme'>
														<h4>USDT</h4>
														<p>Tether USD</p>
													</div>
												</div>
											</div>
											<div class='AllTknsCntRt'>
												<div class='TknNme text-right '>
													<h4>400</h4>
													<p>
														<img
															src={slceqlImg}
															class='img-fluid mr-2'
														/>
														$ 400.00
													</p>
												</div>
											</div>
										</div>
										<div class='AllTknsCnt'>
											<div class='AllTknsCntLft'>
												<div class='FvStr mr-3'>
													<span class='fa fa-star-o checked'></span>
												</div>
												<div class='d-flex'>
													<div class='TknImg'>
														<img
															src={slcttknicon3}
															class='img-fluid mr-2'
														/>
													</div>
													<div class='TknNme'>
														<h4>AAVE</h4>
														<p>Aave</p>
													</div>
												</div>
											</div>
											<div class='AllTknsCntRt'>
												<div class='TknNme text-right '>
													<h4>0</h4>
													<p>
														<img
															src={slceqlImg}
															class='img-fluid mr-2'
														/>
														$ 0.00
													</p>
												</div>
											</div>
										</div>
									</div>
								</TabPane>
							</TabContent>
							<div class='text-center MngTkns'>
								<a onClick={mngtkntgl}>
									<img
										src={mngtknedit}
										class='mr-2 ig-fluid'
									/>
									Manage Tokens
								</a>
							</div>
						</div>
					</ModalBody>
				</Modal>
				<Modal
					isOpen={modal2}
					toggle={mngtkntgl}
					className='CnctWlltMdl MngTknMdl'
				>
					<ModalBody>
						<div class='MdlHdr mb-4  BrdBttm'>
							<h5 class=''>
								<a href=''>
									<img src={bkarrImg} class='mr-3' />
								</a>
								Manage Tokens
							</h5>
							<button
								type='button'
								class='close MdlClosBtn'
								onClick={mngtkntgl}
							>
								<span aria-hidden='true'>&times;</span>
							</button>
						</div>
						<div class='MngTknMdlTb'>
							<Nav tabs>
								<NavItem>
									<NavLink
										className={classnames({
											active: activeTab === '1'
										})}
										onClick={() => {
											mngtabtgl('1')
										}}
									>
										Lists
									</NavLink>
								</NavItem>
								<NavItem>
									<NavLink
										className={classnames({
											active: activeTab === '2'
										})}
										onClick={() => {
											mngtabtgl('2')
										}}
									>
										Tokens
									</NavLink>
								</NavItem>
							</Nav>
							<TabContent activeTab={activeTab}>
								<TabPane tabId='1'>
									<div class='AllTknsSec'>
										<div class='AllTknsCnt MngTknBx'>
											<div class='AllTknsCntLft'>
												<div class='d-flex'>
													<div class='TknImg'>
														<img
															src={slcttknicon1}
															class='img-fluid mr-2'
														/>
													</div>
													<div class='TknNme'>
														<h4>STMX</h4>
														<p>StormX</p>
													</div>
												</div>
											</div>
											<div class='AllTknsCntRt OnOffSwitch'>
												<label class='switch'>
													<input
														type='checkbox'
														class='switch-input'
													/>
													<span
														class='switch-label'
														data-on='On'
														data-off='Off'
													></span>
													<span class='switch-handle'></span>
												</label>
											</div>
										</div>
										<div class='AllTknsCnt MngTknBx'>
											<div class='AllTknsCntLft'>
												<div class='d-flex'>
													<div class='TknImg'>
														<img
															src={slcttknicon2}
															class='img-fluid mr-2'
														/>
													</div>
													<div class='TknNme'>
														<h4>USDT</h4>
														<p>Tether USD</p>
													</div>
												</div>
											</div>
											<div class='AllTknsCntRt OnOffSwitch'>
												<label class='switch'>
													<input
														type='checkbox'
														class='switch-input'
													/>
													<span
														class='switch-label'
														data-on='On'
														data-off='Off'
													></span>
													<span class='switch-handle'></span>
												</label>
											</div>
										</div>
										<div class='AllTknsCnt MngTknBx'>
											<div class='AllTknsCntLft'>
												<div class='d-flex'>
													<div class='TknImg'>
														<img
															src={slcttknicon3}
															class='img-fluid mr-2'
														/>
													</div>
													<div class='TknNme'>
														<h4>AAVE</h4>
														<p>Aave</p>
													</div>
												</div>
											</div>
											<div class='AllTknsCntRt OnOffSwitch'>
												<label class='switch'>
													<input
														type='checkbox'
														class='switch-input'
													/>
													<span
														class='switch-label'
														data-on='On'
														data-off='Off'
													></span>
													<span class='switch-handle'></span>
												</label>
											</div>
										</div>
										<div class='AllTknsCnt MngTknBx'>
											<div class='AllTknsCntLft'>
												<div class='d-flex'>
													<div class='TknImg'>
														<img
															src={slcttknicon4}
															class='img-fluid mr-2'
														/>
													</div>
													<div class='TknNme'>
														<h4>AMP</h4>
														<p>Amp Coin</p>
													</div>
												</div>
											</div>
											<div class='AllTknsCntRt OnOffSwitch'>
												<label class='switch'>
													<input
														type='checkbox'
														class='switch-input'
													/>
													<span
														class='switch-label'
														data-on='On'
														data-off='Off'
													></span>
													<span class='switch-handle'></span>
												</label>
											</div>
										</div>
										<div class='AllTknsCnt MngTknBx'>
											<div class='AllTknsCntLft'>
												<div class='d-flex'>
													<div class='TknImg'>
														<img
															src={slcttknicon5}
															class='img-fluid mr-2'
														/>
													</div>
													<div class='TknNme'>
														<h4>APE</h4>
														<p>Ape Coin</p>
													</div>
												</div>
											</div>
											<div class='AllTknsCntRt OnOffSwitch'>
												<label class='switch'>
													<input
														type='checkbox'
														class='switch-input'
													/>
													<span
														class='switch-label'
														data-on='On'
														data-off='Off'
													></span>
													<span class='switch-handle'></span>
												</label>
											</div>
										</div>
										<div class='AllTknsCnt MngTknBx'>
											<div class='AllTknsCntLft'>
												<div class='d-flex'>
													<div class='TknImg'>
														<img
															src={slcttknicon6}
															class='img-fluid mr-2'
														/>
													</div>
													<div class='TknNme'>
														<h4>QDRX</h4>
														<p>Quadrix Token</p>
													</div>
												</div>
											</div>
											<div class='AllTknsCntRt OnOffSwitch'>
												<label class='switch'>
													<input
														type='checkbox'
														class='switch-input'
													/>
													<span
														class='switch-label'
														data-on='On'
														data-off='Off'
													></span>
													<span class='switch-handle'></span>
												</label>
											</div>
										</div>
										<div class='AllTknsCnt MngTknBx'>
											<div class='AllTknsCntLft'>
												<div class='d-flex'>
													<div class='TknImg'>
														<img
															src={slcttknicon7}
															class='img-fluid mr-2'
														/>
													</div>
													<div class='TknNme'>
														<h4>BNT</h4>
														<p>
															Banchor Network
															Token
														</p>
													</div>
												</div>
											</div>
											<div class='AllTknsCntRt OnOffSwitch'>
												<label class='switch'>
													<input
														type='checkbox'
														class='switch-input'
													/>
													<span
														class='switch-label'
														data-on='On'
														data-off='Off'
													></span>
													<span class='switch-handle'></span>
												</label>
											</div>
										</div>
									</div>
								</TabPane>
								<TabPane tabId='2'>
									<div class='TknInptGrp InptGrpAppnd mb-5'>
										<div class='input-group mb-3'>
											<input
												type='text'
												class='form-control'
												placeholder='0x3ee2200efb3400fabb9aacf31297cbdd1d435d47'
												aria-label=''
												aria-describedby='copy'
											/>
											<div class='input-group-append'>
												<span
													class='input-group-text'
													id='copy'
												>
													<img
														src={mngcopyImg}
														class='img-fluid'
													/>
												</span>
											</div>
										</div>
									</div>
									<div class='AllTknsCnt TknImprt'>
										<div class='AllTknsCntLft'>
											<div class='d-flex'>
												<div class='TknImg'>
													<img
														src={slcttknicon8}
														class='img-fluid mr-2'
													/>
												</div>
												<div class='TknNme'>
													<h4>ADA</h4>
													<p>Cardano</p>
												</div>
											</div>
										</div>
										<div class='AllTknsCntRt'>
											<button
												class='btn BtnImprt'
												type=''
											>
												Import
											</button>
										</div>
									</div>
									<div class='BrdTop pt-4'>
										<div class='NoTkns'>
											<p>You have 0 custom tokens</p>
										</div>
									</div>
									<div class='EmtyDiv'></div>
								</TabPane>
							</TabContent>
						</div>
					</ModalBody>
				</Modal>
			</div>
		</>
	)
}

export default Swap
