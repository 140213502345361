import { createSlice } from '@reduxjs/toolkit'

const initialState = {}

const cmsSlice = createSlice({
	name: 'cms',
	initialState,
	reducers: {},
})


export default cmsSlice.reducer
