import Slider from 'react-slick'

import upcmginvt1Img from '../../assets/images/upcmg-invt-icon1.png'
import upcmginvt2Img from '../../assets/images/upcmg-invt-icon2.png'
import upcmginvt3Img from '../../assets/images/upcmg-invt-icon3.png'
import upcmginvt4Img from '../../assets/images/upcmg-invt-icon4.png'
import upcmginvt5Img from '../../assets/images/upcmg-invt-icon5.png'
import upcmginvt6Img from '../../assets/images/upcmg-invt-icon6.png'

export const UpcomingInventory = () => {
	var settings = {
		dots: false,
		arrows: true,
		autoplay: true,
		autoplaySpeed: 2000,
		pauseOnHover: true,
		infinite: true,
		slidesToShow: 6,
		slidesToScroll: 1,
		responsive: [
			{
				breakpoint: 1199,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 581,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 380,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1
				}
			}
		]
	}

	return (
		<>
			<div className='UpCmngInvnt'>
				<div className='row align-items-center justify-content-between'>
					<div className='col-auto BrdRit mx-auto'>
						<div className='UpCmngInHdd '>
							<p>Upcoming Inventory</p>
						</div>
					</div>
					<div className='col-xl-10'>
						<Slider {...settings} className='slider1'>
							<div className=''>
								<div className='UpCmgItm'>
									<div className='UpCmgItmImg mr-2'>
										<img src={upcmginvt1Img} className='img-fluid' />
									</div>
									<div className='UpCmgDtls'>
										<h4>Abstergo Ltd.</h4>
										<p>
											Price: <b>50 BUSD</b>
										</p>
									</div>
								</div>
							</div>
							<div className=''>
								<div className='UpCmgItm'>
									<div className='UpCmgItmImg mr-2'>
										<img src={upcmginvt2Img} className='img-fluid' />
									</div>
									<div className='UpCmgDtls'>
										<h4>Acme Co.</h4>
										<p>
											Price: <b>100 BUSD</b>
										</p>
									</div>
								</div>
							</div>
							<div className=''>
								<div className='UpCmgItm'>
									<div className='UpCmgItmImg mr-2'>
										<img src={upcmginvt3Img} className='img-fluid' />
									</div>
									<div className='UpCmgDtls'>
										<h4>Barone LLC.</h4>
										<p>
											Price: <b>100 BUSD</b>
										</p>
									</div>
								</div>
							</div>
							<div className=''>
								<div className='UpCmgItm'>
									<div className='UpCmgItmImg mr-2'>
										<img src={upcmginvt4Img} className='img-fluid' />
									</div>
									<div className='UpCmgDtls'>
										<h4>Biffco Enterprises...</h4>
										<p>
											Price: <b>120 BUSD</b>
										</p>
									</div>
								</div>
							</div>
							<div className=''>
								<div className='UpCmgItm'>
									<div className='UpCmgItmImg mr-2'>
										<img src={upcmginvt5Img} className='img-fluid' />
									</div>
									<div className='UpCmgDtls'>
										<h4>Big Kahuna...</h4>
										<p>
											Price: <b>150 BUSD</b>
										</p>
									</div>
								</div>
							</div>
							<div className=''>
								<div className='UpCmgItm'>
									<div className='UpCmgItmImg mr-2'>
										<img src={upcmginvt6Img} className='img-fluid' />
									</div>
									<div className='UpCmgDtls'>
										<h4>Binford Ltd.</h4>
										<p>
											Price: <b>200 BUSD</b>
										</p>
									</div>
								</div>
							</div>
							<div className=''>
								<div className='UpCmgItm'>
									<div className='UpCmgItmImg mr-2'>
										<img src={upcmginvt6Img} className='img-fluid' />
									</div>
									<div className='UpCmgDtls'>
										<h4>Binford Ltd.</h4>
										<p>
											Price: <b>200 BUSD</b>
										</p>
									</div>
								</div>
							</div>
						</Slider>
					</div>
				</div>
			</div>
		</>
	)
}
