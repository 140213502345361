const Stats = () => {
	return (
		<section className='cntdv'>
			<div className='container p-0'>
				<div className='cntdvp'>
					<div className='cntdvcnt'>
						<h6>3.8 million</h6>
						<h6 className='cntdvcntmb'>3.8 M+</h6>
						<p>Staked</p>
					</div>
					<div className='cntdvcnt'>
						<h6>63.9 million</h6>
						<h6 className='cntdvcntmb'>63.9 M+</h6>
						<p>Total Value Locked</p>
					</div>
					<div className='cntdvcnt'>
						<h6>15.9 million</h6>
						<h6 className='cntdvcntmb'>15.9 M+</h6>
						<p>Liquidity</p>
					</div>
					<div className='cntdvcnt'>
						<h6>7.4 million</h6>
						<h6 className='cntdvcntmb'>7.4 M+</h6>
						<p>Farming</p>
					</div>
					<div className='cntdvcnt'>
						<h6>36.8 million</h6>
						<h6 className='cntdvcntmb'>36.8 M+</h6>
						<p>Trade Room</p>
					</div>
				</div>
			</div>
		</section>
	)
}

export default Stats
