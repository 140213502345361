import bnrImg from '../../assets/images/banner.svg'
import lineImg from '../../assets/images/line.png'
import twitterImg from '../../assets/images/twitter.png'
import telegramImg from '../../assets/images/telegram.png'
import githubImg from '../../assets/images/github-icon.png'

const Banner = () => {
	return (
		<section className='bnrsec'>
			<div className='container'>
				<div className='row justify-content-center'>
					<div className='col-xl-8'>
						<div className='bnrimg'>
							<img src={bnrImg} className='mx-auto img-fluid brndtimg' />
						</div>
						<div className='bncnt'>
							<h1>Quadrix Protocol for</h1>
							<div className='bnrsbcnt'>
								<h3>tokenized inventories</h3>
								<p>
									The bridge between manufacturers, traders, and liquidity
									providers to innovate inventory finance through an open and
									accessible marketplace.
								</p>
							</div>
							<button className='btn primary-btn w-202-h-62 ml-5'>
								Launch App
							</button>
						</div>
					</div>
				</div>
				<div className='row'>
					<div className='col-lg-12'>
						<div className='socdv'>
							<h4>
								Follow us on
								<img src={lineImg} className='ml-3 img-fluid' />
							</h4>
							<div className='socdvdt'>
								<div className='socdvdtcnt'>
									<a href='https://twitter.com/QuadrixApp' target='_blank'>
										<img src={twitterImg} />
									</a>
								</div>
								<div className='socdvdtcnt'>
									<a
										href='https://github.com/QuadrixFoundation/QuadrixApp'
										target='_blank'
									>
										<img src={githubImg} />
									</a>
								</div>
								<div className='socdvdtcnt'>
									<a href='https://t.me/QuadrixApp' target='_blank'>
										<img src={telegramImg} />
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default Banner
