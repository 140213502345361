import lineImg from '../../assets/images/line.png'
import hiwweb from '../../assets/images/hiwweb.png'
import hiwmob from '../../assets/images/hiwmob.png'

const HowItWorks = () => {
	return (
		<section className='rdmpdv '>
			<div className='flwCnt'>
				<div className='container'>
					<h1 data-text='Quadrix Finance Flow'>
						Quadrix Finance Flow
					</h1>
					<h3>
						How it works
						<img src={lineImg} className='ml-2' />
					</h3>
					<img
						src={hiwweb}
						className='img-fluid d-block mx-auto hiwweb'
					/>
					<img
						src={hiwmob}
						className='img-fluid d-block mx-auto hiwmob'
					/>
				</div>
			</div>
		</section>
	)
}

export default HowItWorks
