import { useState, useEffect } from 'react'
import {
	UncontrolledDropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Collapse
} from 'reactstrap'
import { Link } from 'react-router-dom'

import logoImg from '../../assets/images/logo.png'
import enImg from '../../assets/images/en.png'
import dnarrImg from '../../assets/images/dnarr.png'
import downloadImg from '../../assets/images/download.png'

const Header = () => {
	const [isOpen, setIsOpen] = useState(false)
	const [scroll, setScroll] = useState(false)

	useEffect(() => {
		window.addEventListener('scroll', () => {
			setScroll(window.scrollY > 50)
		})
	}, [])

	const toggleNav = () => setIsOpen(!isOpen)
	return (
		<>
			<header>
				<nav
					className={
						scroll
							? 'navbar navbar-expand-xl bg-dark navbar-dark fixed-top py-3 scrolled'
							: 'navbar navbar-expand-xl bg-dark navbar-dark fixed-top pt-3'
					}
				>
					<div className='container'>
						<button
							className='navbar-toggler'
							type='button'
							onClick={toggleNav}
						>
							<span className='navbar-toggler-icon'></span>
						</button>
						<a className='navbar-brand' href='#'>
							<img src={logoImg} />
						</a>
						<button className='wldv'>
							<img src={downloadImg} className='mx-auto d-block' />
						</button>
						<Collapse isOpen={isOpen} navbar>
							<ul className='navbar-nav mx-auto'>
								<li className='nav-item'>
									<UncontrolledDropdown>
										<DropdownToggle>
											Exchange
											<img src={dnarrImg} className='ml-1 dnarr' />
										</DropdownToggle>
										<DropdownMenu>
											{/* <Link to='/swap'> */}
											<DropdownItem>Swap</DropdownItem>
											{/* </Link> */}
											<DropdownItem>Liquidity</DropdownItem>
										</DropdownMenu>
									</UncontrolledDropdown>
								</li>
								<li className='nav-item'>
									<UncontrolledDropdown>
										<DropdownToggle>
											Staking
											<img src={dnarrImg} className='ml-1 dnarr' />
										</DropdownToggle>
										<DropdownMenu>
											<DropdownItem>Staking</DropdownItem>
											<DropdownItem>Vesting</DropdownItem>
										</DropdownMenu>
									</UncontrolledDropdown>
								</li>
								<li className='nav-item'>
									<a className='nav-link' href='#'>
										Farming
									</a>
								</li>
								<li className='nav-item'>
									<a className='nav-link' href='#'>
										Trade Room
									</a>
								</li>
								<li className='nav-item'>
									<a className='nav-link' href='#'>
										Lottery
									</a>
								</li>
							</ul>
							<div className='lgcndv'>
								{/* <UncontrolledDropdown>
                                    <DropdownToggle>
                                    <img src={enImg}/><span>EN</span><img src={dnarrImg} className="dnarr"/>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem><img src={enImg} className="mr-2" />EN</DropdownItem>
                                        <DropdownItem><img src={enImg} className="mr-2"/>GER</DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown> */}
								<button className='btn primary-btn w-179-h-53'>
									Connect Wallet
								</button>
							</div>
						</Collapse>
					</div>
				</nav>
			</header>
		</>
	)
}

export default Header
