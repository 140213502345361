import InnerHeader from '../../components/InnerHeader'
import InnerFooter from '../../components/InnerFooter'
import useScrollToTop from '../../hooks/useScrollToTop'

const NftTerms = props => {
	useScrollToTop()

	return (
		<>
			<div className='cmndv cmndvcms'>
				<InnerHeader />
				<div className='cntldvinr'>
					<div className='container container-cms'>
						<div className='pcyplydv'>
							<div className='contianer'>
								<div className='pcyplydvtit'>
									<h3>Quadrix Trade Room NFT Marketplace Terms</h3>
								</div>
								<div className='pcyplydvapp'>
									<span>Quadrix App</span>
									<span>
										Last modified:<label> October 11, 2022</label>
									</span>
								</div>
								<div className='pcyplydvcnt'>
									<h5>1. Acceptance of NFT Terms; Modification of NFT Terms</h5>
									<p>
										Welcome to the Quadrix Trade Room NFT Marketplace owned and
										operated by Quadrix Foundation, Switzerland (the "Company",
										"we", "us", or "our"). These Terms and Conditions ("Trade
										Room NFT Terms") constitute a legally binding agreement
										between the Company and each registered or unregistered end
										user (each, a "User", "you" or "your") of the Quadrix Trade
										Room NFT Marketplace located at <span>www.quadrix.app</span>{' '}
										or such other URL as may be designated by the Company from
										time to time, as well as any mobile apps or other related
										services or applications thereto (collectively, the "Trade
										Room NFT Marketplace")..
									</p>
									<p>
										The Trade Room NFT Marketplace is considered part of the
										"Quadrix Services" as defined in the Quadrix Terms of Use ("
										<span>Quadrix Terms</span>") set forth here. The Quadrix
										Terms are incorporated by reference into these Trade Room
										NFT Terms, and also govern your use of and access to the
										Trade Room NFT Marketplace. In the event of a conflict
										between these terms of these Trade Room NFT Terms and the
										Quadrix Terms, the Trade Room NFT Terms will supersede and
										control.
									</p>
									<p>
										Please carefully review these Trade Room NFT Terms and the
										Quadrix Terms. By accessing and using the Trade Room NFT
										Marketplace (including by purchasing or bidding on any items
										herein), you are deemed to have read, accepted, executed,
										and agreed to be bound by these Trade Room NFT Terms
										(including the Quadrix Terms as incorporated by reference
										herein). We may change or amend the Trade Room NFT
										Marketplace or these Trade Room NFT Terms at any time at our
										sole and absolute discretion.{' '}
									</p>
									<p>
										Any changes to these Trade Room NFT Terms will be in effect
										as of the "Last Revised" date referred to at the top of this
										page. You acknowledge and agree that the form and nature of
										the Trade Room NFT Marketplace, and any part of it, may
										change from time to time without prior notice to you, and
										that we may add new or remove existing features and change
										any part of the Trade Room NFT Marketplace.{' '}
									</p>
									<p>
										IF ANY PROVISION OF THESE TRADE ROOM NFT TERMS OR THE
										QUADRIX TERMS OR ANY FUTURE CHANGES ARE UNACCEPTABLE TO YOU,
										DO NOT USE OR CONTINUE TO USE THE TRADE ROOM NFT
										MARKETPLACE. YOUR CONTINUED USE OF THE TRADE ROOM NFT
										MARKETPLACE FOLLOWING THE POSTING OF ANY NOTICE OF ANY
										CHANGE TO THESE TERMS OF SERVICE SHALL CONSTITUTE YOUR
										ACCEPTANCE AND AGREEMENT TO SUCH CHANGE.{' '}
									</p>
									<p>
										ARBITRATION NOTICE: THE QUADRIX TERMS CONTAIN AN ARBITRATION
										CLAUSE. EXCEPT FOR CERTAIN TYPES OF DISPUTES MENTIONED IN
										THAT ARBITRATION CLAUSE AND GOVERNING LAW CLAUSE, YOU AND
										THE COMPANY AGREE THAT DISPUTES BETWEEN US WILL BE RESOLVED
										BY MANDATORY BINDING ARBITRATION IN ACCORDANCE WITH THE
										ARBITRATION CLAUSE AND GOVERNING LAW CLAUSE IN THE QUADRIX
										TERMS, AND YOU AND THE COMPANY WAIVE ANY RIGHT TO
										PARTICIPATE IN A CLASS-ACTION LAWSUIT OR CLASS-WIDE
										ARBITRATION.{' '}
									</p>
								</div>
								<div className='pcyplydvcnt'>
									<h5>2. Overview of the Trade Room NFT Marketplace</h5>
									<p>
										The Trade Room NFT Marketplace provides you with the
										opportunity to create (or as we call it, mint), sell,
										purchase, bid on, collect, trade, showcase and otherwise
										transact digital blockchain collectibles, which may be
										represented as a non-fungible token ("NFT") linked with
										certain digital media or digital inventory ("NFT Media"). We
										facilitate transactions between buyer and seller of an NFT,
										but we are not a party to any agreement between buyer and
										seller of an NFT on the Trade Room NFT Marketplace. We
										collect revenue on the Trade Room NFT Marketplace via
										transaction fees and other applicable fees which we display
										when you interact with the Trade Room NFT Marketplace.
									</p>
									<p>
										For the avoidance of doubt, NFTs transacted on the Trade
										Room NFT Marketplace are considered "Digital Assets" as
										defined in the Quadrix Terms. The Company reserves the right
										to (but is not required or obligated to be) take any action
										in relation to any disputes arising from purchases via the
										Trade Room NFT Marketplace, including in connection with any
										auctions or other purchase methods.
									</p>
									<p>
										For Trade Room NFT Sellers: By minting, providing, or
										selling an NFT through the Trade Room NFT Marketplace, you
										hereby represent and warrant that you own all legal right,
										title, and interest in all intellectual property rights to
										the NFT Media linked or associated with such NFT, or you are
										legally authorized by the intellectual property owner to
										mint, provide or sell the NFT on the Trade Room NFT
										Marketplace.{' '}
									</p>
									<p>
										Except for NFTs minted on the Trade Room NFT Marketplace, in
										order to list any NFT for sale on the Trade Room NFT
										Marketplace, you must first apply to register your Trade
										Room account and deposit the NFT for sale for custody with
										the Company until such time as the NFT is sold or you decide
										to remove the NFT from the Trade Room NFT Marketplace. For
										clarity, the Company has no obligation or liability to you
										for keeping, storing, or helping you recover any NFT Media
										associated with your NFTs.{' '}
									</p>
									<p>
										For Trade Room NFT Buyers: When you purchase an NFT, you own
										the NFT that is associated with certain NFT Media, but you
										do not own any intellectual property rights in such NFT
										Media except for the license grants expressly set forth
										herein. The Company will help to evaluate or provide you
										with information about a seller of an NFT by enrolling the
										companies and NFT sellers in the Quadrix platform under the
										section “verified manufacturers”.{' '}
									</p>
									<p>
										However, such information is provided for informational
										purposes only. You bear full responsibility for verifying
										the authenticity, legitimacy, and identity of any NFT you
										purchase on the Trade Room NFT Marketplace. Notwithstanding
										any sale clearing period that may be implemented for the
										sale of any NFTs, you acknowledge that the Company is not
										required or obligated to adjudicate or decide on any
										disputes in connection with any NFTs sold on the Trade Room
										NFT Marketplace.{' '}
									</p>
								</div>
								<div className='pcyplydvcnt'>
									<h5>3. License to Your Content</h5>
									<p>
										In connection with your use of the Trade Room NFT
										Marketplace, you may be able to post, upload, or submit
										content to be made available through the Trade Room NFT
										Marketplace, including NFT Media that is tied to NFTs you
										wish to sell on the Trade Room NFT Marketplace as a seller,
										and any other content associated with your NFTs ("Your
										Content"). You retain all rights to Your Content you post,
										upload, submit, or otherwise make available through the
										Trade Room NFT Marketplace, except for rights expressly
										granted herein. In order to operate the NFT Marketplace, we
										must obtain from your certain license rights in Your Content
										so that actions we take in operating the Trade Room NFT
										Marketplace are not considered legal violations.
									</p>
									<p>
										Accordingly, by using the Trade Room NFT Marketplace and
										uploading Your Content or otherwise made Your Content
										available, you grant us a license to access, use, host,
										cache, store, copy, reproduce, transmit, display, publish,
										distribute, adapt, and modify (for technical purposes, e.g.,
										making sure content is viewable on smartphones as well as
										computers and other devices) Your Content in any and all
										media or distribution methods (now know or later developed)
										but solely as required to be able to operate and provide
										services of the Trade Room NFT Marketplace.
									</p>
									<p>
										You agree that this license includes the right for us to
										provide, promote, and improve the Trade Room NFT Marketplace
										and to make Your Content available to other companies,
										organizations, or individuals for the distribution,
										promotion, or publication of Your Content on other media and
										services..{' '}
									</p>
									<p>
										You agree that these rights and licenses are royalty free,
										transferable, sub-licensable, worldwide, and irrevocable
										(for so long as Your Content is stored with us), and include
										a right for us to make Your Content available to, and pass
										these rights along to, others with whom we have contractual
										relationships related to the provision of the Trade Room NFT
										Marketplace, and solely for purpose of providing the Trade
										Room NFT Marketplace, and to otherwise permit access to
										disclose Your Content to third parties if we determine such
										access is necessary to comply with our legal obligations.{' '}
									</p>
									<p>
										As part of the foregoing license grant, you agree that the
										other Users of the Trade Room NFT Marketplace shall have the
										right to comment on and/or tag Your Content and/or to use,
										publish, display, modify or include a copy of Your Content
										as part of their own use of the Trade Room NFT Marketplace;
										except that the foregoing shall not apply to any of Your
										Content that you post privately for non-public display on
										the Trade Room NFT Marketplace.{' '}
									</p>
									<p>
										By posting or submitting Your Content to the Trade Room NFT
										Marketplace, you represent and warrant that you have, or
										have obtained, all rights, licenses, consents, permissions,
										power and/or authority necessary to grant the rights granted
										herein for Your Content. You agree that Your Content will
										not contain material subject to copyright or other
										proprietary rights, unless you have the necessary permission
										or are otherwise legally entitled to post the material and
										grant us the license described above.{' '}
									</p>
									<p>
										If you sell an NFT through the Trade Room NFT Marketplace,
										you grant to the buyer of the NFT a worldwide,
										non-exclusive, non-transferable, royalty-free license to
										use, copy, and display the NFT Media for such purchased NFT,
										solely for the following purposes: <span>(a)</span> for the
										buyer's own personal use; <span>(b)</span> as part of a
										marketplace that permits the purchase and sale of such NFTs,
										provided that the marketplace cryptographically verifies
										each NFT's owner's rights to display the NFT Media for their
										NFTs to ensure that only the actual owner can display the
										NFT Media; or <span>(c)</span> as part of a third-party
										website or application that permits the inclusion,
										involvement, or participation of your NFT, provided that the
										website/application cryptographically verifies each NFT's
										owner's rights to display the NFT Media for their NFTs to
										ensure that only the actual owner can display the NFT Media,
										and provided that the NFT Media is no longer visible once
										the owner of the NFTs leaves the website/application (the
										"NFT Purchase License").{' '}
									</p>
									<p>
										If you are a buyer of NFTs, then you acknowledge and agree
										that the NFT Purchase License set forth above only lasts as
										long as you are the valid owner and holder of the NFT
										associated with the licensed NFT Media. If you sell or
										transfer the NFT to another person, this NFT Purchase
										License will transfer to such other owner or holder of the
										NFT, and you will no longer have the benefits of such NFT
										Purchase License. Unless otherwise specified by the seller
										of an NFT in writing, your purchase of an NFT does not give
										you the right to publicly display, perform, distribute, sell
										or otherwise reproduce the NFT or its related NFT Media for
										any commercial purpose.{' '}
									</p>
									<p>
										If you sell an NFT, you agree that you will not have any
										claims against the Company for any breach of these NFT Terms
										by a purchaser, including if they make commercial use of the
										related NFT Media in breach of these NFT Terms.{' '}
									</p>
									<p>
										We have the right to remove or refuse to post any of Your
										Content, including NFTs, (a) for any or no reason in our
										sole discretion; and (b) take any action with respect to
										Your Content that we deem necessary or appropriate in our
										sole discretion, including if we believe that Your Content
										violates these Trade Room NFT Terms, infringes any
										intellectual property right of any person or entity,
										threatens the personal safety of Users of the Trade Room NFT
										Marketplace or the public, or could create liability for the
										Company or other Users.{' '}
									</p>
								</div>
								<div className='pcyplydvcnt'>
									<h5>
										4. Specific Terms for the Premium Trade Room and Creators
									</h5>
									<p>
										As part of the Trade Room NFT Marketplace, the Company
										offers a premium platform (the "Premium Trade Room ") which
										only allows creators that are audited and approved by the
										Company ("Invited Creators") to mint and sell NFTs (the
										"Creator's NFTs") associated with their original NFT Media
										(the "Creator's NFT Media").
									</p>
									<p>
										The Company may enter into an addendum with any Invited
										Creator (a "Creator Addendum") setting forth terms for using
										the Premium Trade Room as an Invited Creator. If there is a
										conflict between a Creator Addendum and these NFT Terms, the
										provisions of the Creator Addendum shall take precedence for
										such Invited Creator.
									</p>
									<p>
										The Invited Creator hereby grants to the Company a
										perpetual, irrevocable, and exclusive right and license to
										use, reproduce, and display the Creator's NFT Media in
										connection with the promotion of the Creator's NFT Media,
										the Creator's NFTs and the Trade Room NFT Marketplace.{' '}
									</p>
									<p>
										For clarity, unless otherwise specified in a Creator
										Addendum, the exclusive license granted above means that
										Invited Creator cannot itself, or grant to any other party
										any right to, use, reproduce, display the Creator's NFT
										Media in connection with non-fungible tokens or any other
										blockchain collectibles, platforms or services.
									</p>
									<p>
										In order for the Company to exercise its rights to the
										Creator's NFT Media, Invited Creator will provide the
										Company with high-resolution images and other embodiments of
										such NFT Media as reasonably requested by the Company. The
										Company shall have the sole control over the promotion and
										marketing of the Creator's NFTs, including the sole
										discretion to select certain Creator's NFTs or Invited
										Creators to participate in events hosted by the Company.{' '}
									</p>
									<p>
										As reasonably requested by the Company, Invited Creator will
										support the Company in the promotion or marketing of the
										Creator's NFTs through participation in the marketing
										activities as agreed upon by the parties in a Creator
										Addendum. In connection with the promotion of the Creator's
										NFTs, the Invited Creator hereby grants the Company a right
										to use the name, image, photo, biography, signature, and
										likeness of the Invited Creator solely in connection with
										the marketing and promotion of the Creator's NFTs.{' '}
									</p>
									<p>
										Invited Creator will not engage in any promotion or
										marketing of the Company, the Trade Room NFT Marketplace, or
										any Creator's NFTs in a manner that is misleading or
										deceptive or not in compliance with applicable law. Invited
										Creator must disclose any material connection between
										Invited Creator and the Company in any such promotion in a
										clear and conspicuous manner, including in close proximity
										to any such marketing statements.{' '}
									</p>
									<p>
										Invited Creator will not promote or market the Creator's
										NFTs in a manner intended to give buyers the impression that
										such NFTs are investment products or that they can expect
										capital appreciation or derive profits from the purchase of
										such NFTs, or indicate that such NFTs may be characterized
										as securities or any other form of regulated investment
										product in any applicable jurisdiction.
									</p>
									<p>
										If requested by the Company, Invited Creator will reasonably
										cooperate with the Company to validate the authenticity of
										the Creator's NFTs and the Creator's NFT Media..{' '}
									</p>
									<p>
										For the avoidance of doubt, any use of or interaction with
										the Premium Trade Room by any User (including an Invited
										Creator) is governed by terms in this Section 4 and the rest
										of these Trade Room NFT Terms not in direct contradiction
										with the terms in this Section 4.{' '}
									</p>
								</div>
								<div className='pcyplydvcnt'>
									<h5>5. Verification and Payment</h5>
									<p>
										When you make purchases through the Trade Room NFT
										Marketplace, including, without limitation, any purchase for
										NFTs, you must provide and maintain valid payment
										information in connection with your Quadrix Account (as
										defined in the Quadrix Terms) with us. You represent and
										warrant that you are authorized to use the payment method
										you use via the Trade Room NFT Marketplace to make any
										purchase.
									</p>
									<p>
										You authorize us to charge your payment method for the total
										amount of your purchase price. Your order may be suspended
										or canceled for any reason, including if the payment method
										cannot be verified, is invalid, or is otherwise not
										acceptable. Other payment terms for purchases are set forth
										in the relevant sections of the Quadrix Terms.
									</p>
									<p>
										We have no liability to you or any third party for any
										claims or damages that may arise as a result of any payments
										or transactions that you engage in via the Trade Room NFT
										Marketplace, or any other payment or transactions that you
										conduct via the Trade Room NFT Marketplace. We do not
										provide refunds for any purchases that you might make on or
										through the Trade Room NFT Marketplace - whether for NFTs or
										anything else.{' '}
									</p>
									<p>
										You will be solely responsible to pay any and all sales,
										use, value-added and other taxes, duties, and assessments
										(except taxes on our net income) now or hereafter claimed or
										imposed by any governmental authority associated with your
										use of the Trade Room NFT Marketplace or NFTs, except for
										income taxes levied on us as a result of such purchases of
										NFTs.
									</p>
								</div>
								<div className='pcyplydvcnt'>
									<h5>6. Assumption of Risks</h5>
									<p>DO YOUR OWN RESEARCH. You accept and acknowledge that </p>
									<p>
										<span>(i)</span> the value of an NFT is subjective; prices
										of an NFT are subject to volatility and fluctuations in the
										price of cryptocurrency can also materially and adversely
										affect NFT prices;
									</p>
									<p>
										<span>(ii)</span> a lack of use or public interest in NFTs
										could negatively impact the potential utility of NFTs;
									</p>
									<p>
										<span>(iii)</span> the regulatory regime governing NFTs is
										uncertain, and new regulations or policies may materially
										adversely affect the utility of NFTs; and
									</p>
									<p>
										<span>(iv)</span> there are risks associated with purchasing
										items associated with content created by third parties
										through peer-to-peer transactions, including but not limited
										to, the risk of purchasing counterfeit items, mislabeled
										items, items that are vulnerable to metadata decay, items on
										smart contracts with bugs, and items that may become
										untransferable. You represent and warrant that you have done
										sufficient research before making any decisions to sell,
										buy, transfer, or otherwise interact with any NFTs. You
										further acknowledge and agree it is your sole responsibility
										to carry out all necessary due diligence for all your
										activities relating to NFTs, and you represent and warrant
										that you have not and are not relying on, and shall have no
										remedies, in respect of any statement or representation made
										by the Company and/or Quadrix (as defined in the Quadrix
										Terms) in relation to any sale, buy, transfer or interaction
										otherwise with any NFTs.
									</p>
									<p>
										Any purchase or sale you make, accept or facilitate outside
										of the Trade Room NFT Marketplace of an NFT will be entirely
										at your risk. You acknowledge that you have obtained
										sufficient information to make an informed decision to
										purchase an NFT, including carefully reviewing the code of
										the smart contract and the NFT and fully understand and
										accept the functions of the same. We do not control or
										endorse purchases or sales of NFTs outside of the Trade Room
										NFT Marketplace. We expressly deny and disclaim any
										liability to you and deny any obligation to indemnify you or
										hold you harmless for any losses you may incur by
										transacting, or facilitating transactions, in NFTs outside
										of the Trade Room NFT Marketplace.{' '}
									</p>
									<p>
										Certain parts of the Trade Room NFT Marketplace may display,
										include or make available content, data, information,
										applications, or materials from third parties ("Third Party
										Materials"). By using the Trade Room NFT Marketplace, you
										acknowledge and agree that the Company is not responsible
										for examining or evaluating the content, accuracy,
										completeness, availability, timeliness, validity, copyright
										compliance, legality, decency, quality, or any other aspect
										of such Third Party Materials.
									</p>
									<p>
										We do not warrant or endorse and do not assume and will not
										have any liability or responsibility to you or any other
										person for any third-party services, Third Party Materials,
										or any other materials, products, or services of third
										parties.
									</p>
									<p>
										If you have a dispute with one or more users, YOU RELEASE US
										FROM CLAIMS, DEMANDS, AND DAMAGES OF EVERY KIND AND NATURE,
										KNOWN AND UNKNOWN, ARISING OUT OF OR IN ANY WAY CONNECTED
										WITH SUCH DISPUTES. IN ENTERING INTO THIS RELEASE YOU
										EXPRESSLY WAIVE ANY PROTECTIONS (WHETHER STATUTORY OR
										OTHERWISE) THAT WOULD OTHERWISE LIMIT THE COVERAGE OF THIS
										RELEASE TO INCLUDE THOSE CLAIMS WHICH YOU MAY KNOW OR
										SUSPECT TO EXIST IN YOUR FAVOUR AT THE TIME OF AGREEING TO
										THIS RELEASE
									</p>
								</div>
								<div className='pcyplydvcnt'>
									<h5>7. Limitation of Liability</h5>
									<p>
										TO THE FULLEST EXTENT PERMITTED BY LAW, YOU AGREE THAT IN NO
										EVENT WILL THE COMPANY BE LIABLE TO YOU OR ANY THIRD PARTY
										FOR ANY LOST PROFIT OR ANY INDIRECT, CONSEQUENTIAL,
										EXEMPLARY, INCIDENTAL, SPECIAL OR PUNITIVE DAMAGES RELATED
										TO THE LOSS OF REVENUE, LOSS OF PROFIT, LOSS OF BUSINESS OR
										ANTICIPATED SAVING, LOSS OF USE, LOSS OF GOODWILL OR LOSS OF
										DATA, WHETHER CAUSED BY TORT (INCLUDING NEGLIGENCE), BREACH
										OF CONTRACT, OR OTHERWISE, EVEN IF PARTIES HAVE BEEN ADVISED
										OF THE POSSIBILITY OF SUCH DAMAGES; AND (B) FOR ANY OTHER
										CLAIM, DEMAND, OR DAMAGES WHATSOEVER RESULTING FROM OR
										ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OF THE
										DELIVERY, USE, OR PERFORMANCE OF THE SERVICE.
									</p>
									<p>
										ACCESS TO, AND USE OF, THE SERVICE, PRODUCTS OR THIRD-PARTY
										SITES, AND PRODUCTS ARE AT YOUR OWN DISCRETION AND RISK, AND
										YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR
										COMPUTER SYSTEM OR MOBILE DEVICE OR LOSS OF DATA RESULTING
										THEREFROM.
									</p>
									<p>
										NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED HEREIN,
										IN NO EVENT SHALL THE MAXIMUM AGGREGATE LIABILITY OF THE
										COMPANY ARISING OUT OF OR IN ANY WAY RELATED TO THESE TERMS,
										THE ACCESS TO AND USE OF THE SERVICE, CONTENT, OR NFTS
										EXCEED $100.{' '}
									</p>
								</div>
								<div className='pcyplydvcnt'>
									<h5>8. Termination</h5>
									<p>
										If you breach any of the provisions of these Trade Room NFT
										Terms, we reserve the right, with or without notice and in
										our sole discretion, to suspend, disable, terminate, or
										delete your account and/or your ability to access or use the
										Trade Room NFT Marketplace (or any part of the foregoing) at
										any time and for any or no reason, and you acknowledge and
										agree that we shall have no liability or obligation to you
										in such event and that you will not be entitled to a refund
										of any amounts that you have already paid to us.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<InnerFooter />
			</div>
		</>
	)
}

export default NftTerms
