import { InnerHeader, InnerFooter } from '../../components'
import useScrollToTop from '../../hooks/useScrollToTop'

const Disclaimer = () => {
	useScrollToTop()

	return (
		<>
			<div className='cmndv cmndvcms'>
				<InnerHeader />
				<div className='cntldvinr'>
					<div className='container container-cms'>
						<div className='pcyplydv'>
							<div className='contianer'>
								<div className='pcyplydvtit'>
									<h3>Quadrix protocol disclaimer</h3>
								</div>
								<div className='pcyplydvapp'>
									<span>Quadrix App</span>
									<span>
										Last modified:
										<label> October 11, 2022</label>
									</span>
								</div>
								<div className='pcyplydvcnt'>
									<p>
										Quadrix is a decentralized peer-to-peer protocol that people
										can use to create liquidity and trade BEP-20 tokens. The
										Quadrix protocol is made up of free, public, open-source or
										source-available software including a set of smart contracts
										that are deployed on the Binance Smart Chain. Your use of
										the Quadrix protocol involves various risks, including, but
										not limited to, losses while digital assets are being
										supplied to the Quadrix protocol and losses due to the
										fluctuation of prices of tokens in a trading pair or
										liquidity pool.
									</p>
									<p>
										Before using the Quadrix protocol, you should review the
										relevant documentation to make sure you understand how the
										Quadrix protocol works. Additionally, just as you can access
										email protocols such as SMTP through multiple email clients,
										you can access the Quadrix protocol through dozens of web or
										mobile interfaces. You are responsible for doing your own
										diligence on those interfaces to understand the fees and
										risks they present.
									</p>
								</div>
								<div className='pcyplydvcnt'>
									<p>
										AS DESCRIBED IN THE QUADRIX PROTOCOL LICENSES, THE QUADRIX
										PROTOCOL IS PROVIDED "AS IS", AT YOUR OWN RISK, AND WITHOUT
										WARRANTIES OF ANY KIND. Although Quadrix Foundation d/b/a/
										"Quadrix Foundation" ( "<span>Quadrix Foundation</span>" )
										developed much of the initial code for the Quadrix protocol,
										it does not provide, own, or control the Quadrix protocol,
										which is run by smart contracts deployed on the Binance
										Smart Chain. Upgrades and modifications to the protocol are
										managed in a community-driven way by holders of the QDRX
										governance token.
									</p>
									<p>
										No developer or entity involved in creating the Quadrix
										protocol will be liable for any claims or damages whatsoever
										associated with your use, inability to use, or your
										interaction with other users of, the Quadrix protocol,
										including any direct, indirect, incidental, special,
										exemplary, punitive or consequential damages, or loss of
										profits, cryptocurrencies, tokens, or anything else of
										value.{' '}
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<InnerFooter />
			</div>
		</>
	)
}

export default Disclaimer
