import { createRoot } from 'react-dom/client'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { Provider } from 'react-redux'
import { Web3ReactProvider } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import 'bootstrap/dist/css/bootstrap.min.css'

import App from './app/App'
import { store } from './app/store'

import './scss/style.scss'

const getLibrary = provider => {
	const library = new Web3Provider(provider)
	library.pollingInterval = 12000
	return library
}

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
	<Provider store={store}>
		<Web3ReactProvider getLibrary={getLibrary}>
			<BrowserRouter>
				<Routes>
					<Route path='/*' element={<App />} />
				</Routes>
			</BrowserRouter>
		</Web3ReactProvider>
	</Provider>
)
