import React, { useEffect, useState } from 'react'
import { Modal, ModalBody } from 'reactstrap'

import cnctwllt1Img from '../../assets/images/cnct-wllt-icon1.svg'
import cnctwllt2Img from '../../assets/images/cnct-wllt-icon2.svg'
import cnctwllt3Img from '../../assets/images/cnct-wllt-icon3.svg'
import cnctwllt4Img from '../../assets/images/cnct-wllt-icon4.svg'
import cnctwllt5Img from '../../assets/images/cnct-wllt-icon5.svg'
import cnctwllt6Img from '../../assets/images/cnct-wllt-icon6.svg'
import cnctwllt7Img from '../../assets/images/cnct-wllt-icon7.svg'
import cnctwllt8Img from '../../assets/images/cnct-wllt-icon8.svg'
import cnctwllt9Img from '../../assets/images/cnct-wllt-icon9.svg'
import cnctwllt10Img from '../../assets/images/cnct-wllt-icon10.svg'
import cnctwllt11Img from '../../assets/images/cnct-wllt-icon11.svg'

import { injectedConnector, coin98Connector, coinbaseConnector, walletConnectConnector, binanceConnector, bloctoConnector } from '../../web3/connectors/connectors'

import useWeb3 from '../../web3/hooks/useWeb3'
import modalService from '../services/modalService'

const ConnectWalletModal = () => {
	const { connect } = useWeb3()

	const [modal, setModal] = useState(false)
	const toggle = () => setModal(prev => !prev)

	useEffect(() => {
		const subscription = modalService
			.getMessage()
			.subscribe(({ component }) => {
				console.log({ component })
				if (component === 'open') {
					setModal(true)
				} else if (component === 'close') {
					setModal(false)
				}
			})
		return () => {
			subscription.unsubscribe()
		}
	}, [])

	const connectWallet = (e, connector) => {
		e.preventDefault()
		try {
			connect(connector)
		} catch (err) {
			console.log(err)
		}
	}

	return (
		<>
			<Modal isOpen={modal} toggle={toggle} className='CnctWlltMdl'>
				<ModalBody>
					<div class='MdlHdr mb-4 pb-2'>
						<h5 class='' id='staticBackdropLabel'>
							Connect Your Wallet
						</h5>
						<button type='button' class='close MdlClosBtn' onClick={toggle}>
							<span aria-hidden='true'>&times;</span>
						</button>
					</div>
					<div class='MdlMainCnt  pb-3'>
						<form class='CnctWllChckBox mb-4'>
							<div class='form-group'>
								<input type='checkbox' id='html' />
								<label for='html'>
									I accept <a href=''>Terms of Use</a> and{' '}
									<a href=''>Privacy Policy</a>
								</label>
							</div>
						</form>
						<div class='row'>
							<div class='col-sm-6 mb-3'>
								<div class='WlltDtls'>
									<a href=''>
										<img src={cnctwllt11Img} class='img-fluid mr-2' />
										Math Wallet
									</a>
								</div>
							</div>
							<div class='col-sm-6 mb-3'>
								<div class='WlltDtls'>
									<a
										href=''
										onClick={e => connectWallet(e, walletConnectConnector)}
									>
										<img src={cnctwllt2Img} class='img-fluid mr-2' />
										Wallet Connect
									</a>
								</div>
							</div>
							<div class='col-sm-6 mb-3'>
								<div class='WlltDtls'>
									<a href=''>
										<img src={cnctwllt3Img} class='img-fluid mr-2' />
										TrustWallet
									</a>
								</div>
							</div>
							<div class='col-sm-6 mb-3'>
								<div class='WlltDtls'>
									<a href=''>
										<img src={cnctwllt4Img} class='img-fluid mr-2' />
										BNB Smart Chain
									</a>
								</div>
							</div>
							<div class='col-sm-6 mb-3'>
								<div class='WlltDtls'>
									<a href=''>
										<img src={cnctwllt5Img} class='img-fluid mr-2' />
										SafePal Wallet
									</a>
								</div>
							</div>
							<div class='col-sm-6 mb-3'>
								<div class='WlltDtls'>
									<a href='' onClick={e => connectWallet(e, coin98Connector)}>
										<img src={cnctwllt6Img} class='img-fluid mr-2' />
										Coin98 Wallet
									</a>
								</div>
							</div>
							<div class='col-sm-6 mb-3'>
								<div class='WlltDtls'>
									<a href='' onClick={e => connectWallet(e, binanceConnector)}>
										<img src={cnctwllt7Img} class='img-fluid mr-2' />
										Binance Wallet
									</a>
								</div>
							</div>
							<div class='col-sm-6 mb-3'>
								<div class='WlltDtls'>
									<a href=''>
										<img src={cnctwllt8Img} class='img-fluid mr-2' />
										TokenPocket
									</a>
								</div>
							</div>
							<div class='col-sm-6 mb-3'>
								<div class='WlltDtls'>
									<a href='' onClick={e => connectWallet(e, coinbaseConnector)}>
										<img src={cnctwllt9Img} class='img-fluid mr-2' />
										Coinbase Wallet
									</a>
								</div>
							</div>
							<div class='col-sm-6 mb-3'>
								<div class='WlltDtls'>
									<a href='' onClick={e => connectWallet(e, bloctoConnector)}>
										<img src={cnctwllt10Img} class='img-fluid mr-2' />
										Blocto Wallet
									</a>
								</div>
							</div>
							<div class='col-sm-6 mb-3 d-block mx-auto'>
								<div class='WlltDtls'>
									<a onClick={e => connectWallet(e, injectedConnector)}>
										<img src={cnctwllt1Img} class='img-fluid mr-2' />
										Metamask
									</a>
								</div>
							</div>
						</div>
					</div>
				</ModalBody>
			</Modal>
		</>
	)
}

export default ConnectWalletModal
