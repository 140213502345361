import Marquee from 'react-fast-marquee'

import trderncntImg from '../../assets/images/trderncnt.png'

const MarqueeSection = () => {
	return (
		<section className='trdernsec'>
			<div className='d-flex justify-content-end trdernwntxt'>
				<Marquee direction='right' speed='45'>
					<img src={trderncntImg} className='img-fluid' />
				</Marquee>
			</div>
			<Marquee direction='left' speed='45'>
				<h2>
					TRADE<span>EARN</span>WIN
				</h2>
			</Marquee>
		</section>
	)
}

export default MarqueeSection
