import { Subject } from 'rxjs'

const subject = new Subject()

const modalService = {
	sendMessage: component => subject.next({ component }),
	getMessage: () => subject.asObservable()
}

export default modalService
