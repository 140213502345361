import React from 'react'
import { useNavigate } from 'react-router-dom'

import pnfImg from '../../assets/images/404.png'
const PageNotFound = () => {
	const navigate = useNavigate()
	return (
		<>
			<div className='cmndv cmndvcms'>
				<div className='pnf'>
					<div className='container container-cms'>
						<div className='404page position-relative'>
							<img src={pnfImg} className='img-fluid d-block mx-auto' />
							<div className='inrcnt'>
								<h3>OOPS!</h3>
								<h5> WE ARE SORRY, PAGE NOT FOUND!</h5>
								<p>
									The page you are looking for might have been removed or had
									it’s name changed or is temporarily unavailable.
								</p>
								<button
									className='btn go-back-btn'
									onClick={() => navigate(-1)}
								>
									Go Back
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default PageNotFound
