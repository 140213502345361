import { configureStore } from '@reduxjs/toolkit'

import { api } from './api'
import cmsReducer from '../pages/home/slice'

export const store = configureStore({
	reducer: {
		[api.reducerPath]: api.reducer,
		cms: cmsReducer
	},
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware().concat(api.middleware),
	devTools: true,
})
