import InnerHeader from '../../components/InnerHeader'
import InnerFooter from '../../components/InnerFooter'
import useScrollToTop from '../../hooks/useScrollToTop'

const TradeMark = props => {
	useScrollToTop()

	return (
		<>
			<div className='cmndv cmndvcms'>
				<InnerHeader />
				<div className='cntldvinr'>
					<div className='container container-cms trdmrk'>
						<div className='pcyplydv'>
							<div className='contianer'>
								<div className='pcyplydvtit'>
									<h3>Quadrix Foundation Trademark Guidelines</h3>
								</div>
								<div className='pcyplydvapp'>
									<span>Quadrix App</span>
									<span>
										Last modified:<label> 11, 2022</label>
									</span>
								</div>
								<div className='pcyplydvcnt'>
									<p>
										<span>Quadrix Foundation</span> distributed the open source
										Quadrix protocol and distributes other software. While our
										code is in part available to download, review, and improve
										under open-source and source-available software licenses,
										none of our licenses include a license to use our
										trademarks. Proper use of our trademarks is essential to
										inform users whether or not Quadrix Foundation stands behind
										a product or service. When using Quadrix Foundation
										trademarks, you must comply with these{' '}
										<span>Quadrix Foundation Trademark Guidelines</span>. Just
										like other projects that develop open source software, we
										must enforce our trademark rights to protect our users.
									</p>
								</div>
								<div className='pcyplydvcnt'>
									<h6>
										This policy covers all of our trademarks and services marks,
										whether they are registered or not, including, among others:
									</h6>
									<div className='servmrk'>
										<h4>The trademarks and service marks:</h4>
										<span>A. QUADRIX®</span>
										<span>B. Trade Room™</span>
										<span>C. QUADRIX FOUNDATION™ (“Quadrix Wordmarks”)</span>
										<h4>Quadrix logos.</h4>
									</div>
									<h4>
										If you want to report misuse of a Quadrix Foundation
										trademark, please contact us at: legal@quadrix.app
									</h4>
								</div>
								<div className='pcyplydvcnt'>
									<h5>Acceptable Uses</h5>
									<h6>
										You may do the following without receiving specific
										permission from Quadrix Foundation:
									</h6>
									<ul>
										<li>
											Use Quadrix wordmarks in text to truthfully refer to
											and/or link to unmodified Quadrix smart contracts,
											protocols, interfaces, programs, products, services and
											technologies (“Quadrix software”).
										</li>
										<li>
											Use the Quadrix wordmarks to truthfully describe modified
											versions of Quadrix software that you may create or make
											available. For example, you may say “This software is
											derived from Quadrix software.” or “This service uses
											software derived from Quadrix software.”
										</li>
										<li>
											Use the Quadrix logos in software or aggregators that
											integrate with Quadrix software to truthfully refer to,
											and, where possible, link to the applicable Quadrix
											software hosted on the Binance Smart Chain.
										</li>
										<li>
											Use Quadrix wordmarks to clearly signal to users that
											there is no affiliation with or endorsement by Quadrix
											Foundation.
										</li>
									</ul>
									<p>
										Follow the terms of the open source licenses for Quadrix
										software.
									</p>
								</div>
								<div className='pcyplydvcnt'>
									<h5>
										When allowed, how can I use a Quadrix Foundation trademark?
									</h5>
									<ul>
										<li>
											You must include a trademark attribution notice at the
											first or most prominent mention of the mark on a webpage,
											document or documentation, such as: “[Quadrix Foundation
											trademark] is a trademark of Quadrix Foundation.”
										</li>
										<li>
											You must include a trademark attribution notice at the
											first or most prominent mention of the mark on a webpage,
											document or documentation, such as: “[Quadrix Foundation
											trademark] is a trademark of Quadrix Foundation.”
										</li>
										<li>
											You must always use the wordmarks in their exact form and
											with the correct spelling, neither abbreviated,
											hyphenated, nor combined with any other word or words.
										</li>
										<li>
											You must always use the Quadrix wordmarks only as an
											adjective, never as a noun or verb, or in plural or
											possessive forms.
										</li>
									</ul>
									<p>
										Instead, use the generic term for the Quadrix product or
										service following the trademark. For example: Quadrix
										protocol, Quadrix interface, Sybil website.
									</p>
									<p>
										All other uses of a Quadrix Foundation trademark require our
										prior written permission.
									</p>
									<p>
										Contact us at <span>legal@quadrix.app</span> for more
										information.
									</p>
								</div>
								<div className='pcyplydvcnt'>
									<h5>Unacceptable Uses</h5>
									<ul>
										<li>
											Don’t use our trademarks in anything dishonest or
											fraudulent.
										</li>
										<li>
											Our name is not your name. Don’t use Quadrix Foundation
											trademarks in the name of your smart contract, interface,
											business, product, service, app, domain name, publication,
											or other offering.
										</li>
										<li>
											Don’t use our name for your products. Don’t apply Quadrix
											Foundation trademarks to any product (e.g., a mobile app),
											unless such use is limited to a truthful and descriptive
											reference (e.g., “Mobile App for trading on Quadrix
											interface”).
										</li>
										<li>
											Don’t overemphasize our mark. Don’t display Quadrix
											Foundation trademarks more prominently than your product,
											service, or company name.
										</li>
										<li>
											{' '}
											Don’t create or use confusingly similar names. Don’t use
											Quadrix Foundation trademarks, company names, slogans,
											domain names, or designs that are confusingly similar to
											Quadrix Foundation trademarks, particularly in the name of
											your smart contract, interface, business, product,
											service, app, domain name, publication, or other offering.
										</li>
										<li>
											Don’t imply our sponsorship of your products. Don’t use
											Quadrix Foundation trademarks in a way that incorrectly
											implies affiliation with, sponsorship, endorsement, or
											approval by Quadrix Foundation of your products or
											services.{' '}
										</li>
										<p>
											For example, please do not name your project compatible
											with Quadrix software Uni-[Something] or [Something]-swap.
										</p>
										<li>
											Don’t imply our sponsorship of your activities. Don’t use
											Quadrix Foundation trademarks, or confusingly similar
											trademarks on social media accounts in a way that might
											suggest affiliation with Quadrix Foundation or Quadrix
											software; except if you’ve received prior permission from
											Quadrix Foundation.{' '}
										</li>
										<p>
											For example, you cannot name your account, page, or
											community “Quadrix Protocol” or “Quadrix Team.” However,
											it would be acceptable to name your account, page, or
											community “Fans of Quadrix” or “Information about
											Quadrix”.
										</p>
										<li>
											{' '}
											Don’t create swag with our marks. Don’t use Quadrix
											Foundation trademarks on merchandise for sale (e.g.,
											selling t-shirts, mugs, etc.) unless you have permission
											from Quadrix Foundation.
										</li>
										<li>
											Don’t change or combine our marks. Don’t modify Quadrix
											Foundation trademarks, abbreviate them, or combine them
											with any other symbols, words, or images, or incorporate
											them into a tagline or slogan.
										</li>
									</ul>
									<p>
										We take reasonable steps to protect personally identifiable
										information from loss, misuse, and unauthorized access,
										disclosure, alteration, or destruction. But, you should keep
										in mind that no Internet transmission is ever completely
										secure or error-free. In particular, email sent to or from
										the Sites may not be secure.
									</p>
								</div>
								<div className='pcyplydvcnt'>
									<h5>Others’ Trademarks</h5>
									<p>
										A note on others’ trademarks: Quadrix Foundation manages one
										interface (among many) for accessing the Quadrix protocol,
										which it does not control. Without the involvement of
										Quadrix Foundation, a third-party developer can use the
										Binance Smart Chain protocol to create a token that may
										implicate others’ trademarks or other rights and add that
										token to the Quadrix protocol.{' '}
									</p>
									<p>
										Quadrix Foundation cannot prevent or block any actions
										related to the Quadrix protocol, however, if Quadrix
										Foundation becomes aware of trademark misuse allegations, we
										will work with trademark owners to review the allegations
										and may remove content from the Quadrix Foundation
										interface.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<InnerFooter />
			</div>
		</>
	)
}

export default TradeMark
