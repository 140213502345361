import { InnerHeader, InnerFooter } from '../../components'
import useScrollToTop from '../../hooks/useScrollToTop'

const Privacy = () => {
	useScrollToTop()

	return (
		<>
			<div className='cmndv cmndvcms'>
				<InnerHeader />
				<div className='cntldvinr'>
					<div className='container container-cms'>
						<div className='pcyplydv'>
							<div className='contianer'>
								<div className='pcyplydvtit'>
									<h3>Privacy Policy of Quadrix App</h3>
									<p>
										At Quadrix App, we collect and manage
										user data according to the following
										Privacy Policy.
									</p>
								</div>
								<div className='pcyplydvapp'>
									<span>Quadrix App</span>
									<span>
										Last modified:<label> 11, 2022</label>
									</span>
								</div>
								<div className='pcyplydvcnt'>
									<h5>Data Collected</h5>
									<p>
										We collect information you provide
										directly to us. For example, we collect
										information when you create an account,
										subscribe, participate in any
										interactive features of our services,
										fill out a form, request customer
										support or otherwise communicate with
										us. The types of information we may
										collect include your name, email
										address, postal address, credit card
										information and other contact or
										identifying information you choose to
										provide. We collect anonymous data from
										every visitor of the Website to monitor
										traffic and fix bugs. For example, we
										collect information like web requests,
										the data sent in response to such
										requests, the Internet Protocol address,
										the browser type, the browser language,
										and a timestamp for the request.
									</p>
									<p>
										We also use various technologies to
										collect information, and this may
										include sending cookies to your
										computer. Cookies are small data files
										stored on your hard drive or in your
										device memory that helps us to improve
										our services and your experience, see
										which areas and features of our services
										are popular and count visits. We may
										also collect information using web
										beacons (also known as "tracking
										pixels"). Web beacons are electronic
										images that may be used in our services
										or emails and to track count visits or
										understand usage and campaign
										effectiveness.
									</p>
								</div>
								<div className='pcyplydvcnt'>
									<h5>Use of the Data</h5>
									<p>
										We only use your personal information to
										provide you the Quadrix App services or
										to communicate with you about the
										Website or the services
									</p>
									<p>
										We employ industry standard techniques
										to protect against unauthorized access
										of data about you that we store,
										including personal information.
									</p>
									<p>
										We do not share personal information you
										have provided to us without your
										consent, unless:
									</p>
									<ul>
										<li>
											Doing so is appropriate to carry out
											your own request
										</li>
										<li>
											We believe it's needed to enforce
											our legal agreements or that is
											legally required
										</li>
										<li>
											We believe it's needed to detect,
											prevent or address fraud, security
											or technical issues
										</li>
									</ul>
								</div>
								<div className='pcyplydvcnt'>
									<h5>Sharing of Data</h5>
									<p>
										We only use your personal information to
										provide you the Quadrix App services or
										to communicate with you about the
										Website or the services.
									</p>
									<p>
										We may allow third parties to provide
										analytics services. These third parties
										may use cookies, web beacons and other
										technologies to collect information
										about your use of the services and other
										websites, including your IP address, web
										browser, pages viewed, time spent on
										pages, links clicked and conversion
										information.
									</p>
									<p>
										We also use social buttons provided by
										services like Twitter, Discord,
										LinkedIn, Instagram and Facebook. Your
										use of these third party services is
										entirely optional. We are not
										responsible for the privacy policies
										and/or practices of these third party
										services, and you are responsible for
										reading and understanding those third
										party services' privacy policies.
									</p>
								</div>
								<div className='pcyplydvcnt'>
									<h5>Opt-Out, Communication Preferences</h5>
									<p>
										You may modify your communication
										preferences and/or opt-out from specific
										communications at any time. Please
										specify and adjust your preferences.
									</p>
								</div>
								<div className='pcyplydvcnt'>
									<h5>Security</h5>
									<p>
										We take reasonable steps to protect
										personally identifiable information from
										loss, misuse, and unauthorized access,
										disclosure, alteration, or destruction.
										But, you should keep in mind that no
										Internet transmission is ever completely
										secure or error-free. In particular,
										email sent to or from the Sites may not
										be secure.
									</p>
								</div>
								<div className='pcyplydvcnt'>
									<h5>Changes to the Privacy Policy</h5>
									<p>
										We may amend this Privacy Policy from
										time to time. Use of information we
										collect now is subject to the Privacy
										Policy in effect at the time such
										information is used. If we make major
										changes in the way we collect or use
										information, we will notify you by
										posting an announcement on the Website
										or sending you an email.
									</p>
								</div>
							</div>
						</div>
					</div>
				</div>

				<InnerFooter />
			</div>
		</>
	)
}

export default Privacy
