// import { MathWalletConnector } from '@harmony-react/mathwallet-connector'
import { InjectedConnector } from '@web3-react/injected-connector'
import { Coin98Connector } from '@yay-games/coin98-web3-connector'
import { WalletLinkConnector } from '@web3-react/walletlink-connector'
import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { BscConnector } from '@binance-chain/bsc-connector'
import { BloctoConnector } from '@blocto/blocto-connector'

export const injectedConnector = new InjectedConnector({
	supportedChainIds: [97]
	// supportedChainIds: [56,97]
})

export const coin98Connector = new Coin98Connector({
	supportedChainIds: [97]

	// supportedChainIds: [56,97]
})

export const binanceConnector = new BscConnector({
	supportedChainIds: [97]
})

export const coinbaseConnector = new WalletLinkConnector({
	supportedChainIds: [97]
})
export const walletConnectConnector = new WalletConnectConnector({
	rpc: { 97: 'https://data-seed-prebsc-2-s1.binance.org:8545/' },
	qrcode: true,
	pollingInterval: 12000
})

export const bloctoConnector = new BloctoConnector({
	chainId: 97
	// rpc: NETWORK_URL
})
