import React from 'react'

const Lottery = () => {
	return (
		<section className=' hr_dh_top mb-5'>
			<div className='container container_2'>
				<div className=''>
					<div className='quadrix_lor text-center'>
						<div>
							<h2>Quadrix Lottery</h2>
							<div className='d-flex align-items-center justify-content-center buy_token_cd_draw  '>
								<h4 className='mr-2'>Buy Tickets</h4>
								<label className='round_frn mr-2'></label>
								<h4 className='mr-2'>Wait for draw</h4>
								<label className='round_frn mr-2'></label>
								<h4 className='mr-2'>Check prizes</h4>
							</div>
							<div className='pt-lg-5 pt-2'>
								<h3>Win millions in prizes</h3>
								<p>
									A probably fair, on-chain games. Win big
									with <span>Quadrix</span>
								</p>
							</div>
							<div>
								<button className='btn  nd_btn_buy'>
									Buy Tickets
								</button>
							</div>
						</div>
						<div className='quadrix_lor_card_1'>
							<h1>Lottery</h1>
							<h5>Prize Pool $118,595 in QDRX</h5>
							<div className='pt-3'>
								<button className='btn primary-btn w-187-h-53'>
									Buy with QDRX
								</button>
							</div>
							<p>*Win QDRX if your numbers matches</p>
						</div>
						<div className='quadrix_lor_card_1 quadrix_lor_card_2'>
							<h1>It costs $5/tickets</h1>
							<ul className='text-left pt-3'>
								<li>
									The exact QRDX price is set at the start of
									the round
								</li>
								<li>
									You can Choose either by randomize or manual
									ticket numbers{' '}
								</li>
							</ul>

							<div className='pt-3'>
								<button className='btn primary-btn w-187-h-53'>
									Buy with QDRX
								</button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default Lottery
