import React, { Fragment, useState } from 'react'
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap'
import classnames from 'classnames'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Collapsible from 'react-collapsible'
import 'react-accessible-accordion/dist/fancy-example.css'

import { InnerHeader, InnerFooter } from '../../components'

import liqpooltkn1 from '../../assets/images/liq-pool-tkn1.png'
import liqpooltkn2 from '../../assets/images/liq-pool-tkn2.png'
import liqpooltkn3 from '../../assets/images/liq-pool-tkn3.png'
import liqpooltkn4 from '../../assets/images/liq-pool-tkn4.png'
import liqpooltkn5 from '../../assets/images/liq-pool-tkn5.png'
import liqpooltkn6 from '../../assets/images/liq-pool-tkn6.png'
import liqpooltkn7 from '../../assets/images/liq-pool-tkn7.png'
import liqpooltkn8 from '../../assets/images/liq-pool-tkn8.png'
import liqpooltkn9 from '../../assets/images/liq-pool-tkn9.png'
import liqpooltkn10 from '../../assets/images/liq-pool-tkn10.png'
import liqpooltkn11 from '../../assets/images/liq-pool-tkn11.png'
import liqpooltkn12 from '../../assets/images/liq-pool-tkn12.png'
import liqpooltkn13 from '../../assets/images/liq-pool-tkn13.png'
import grdvirwImg from '../../assets/images/grd-view-icon.svg'
import lstvirwImg from '../../assets/images/lst-view-icon.svg'
import addplusiconImg from '../../assets/images/add-plus-icon.svg'
import addplusicon1Img from '../../assets/images/add-plus-icon1.svg'
import sttng1Img from '../../assets/images/sttng-icon1.svg'
import swpwltImg from '../../assets/images/swp-wallet.svg'
import slcttknImg from '../../assets/images/slct-tkn-arrw.png'
import lstcpyicon1 from '../../assets/images/lst-copy-icon1.svg'
import lstcpyicon2 from '../../assets/images/lst-copy-icon2.svg'
import cllparwdwnImg from '../../assets/images/cllps-arrw-dwn.svg'
import lstcht1 from '../../assets/images/lst-vw-chart1.png'
import lstcht2 from '../../assets/images/lst-vw-chart2.png'
import lstcht3 from '../../assets/images/lst-vw-chart3.png'
import lstcht4 from '../../assets/images/lst-vw-chart4.png'

const Liquidity = props => {
	const [activeTab, setActiveTab] = useState('1')
	const toggle = tab => {
		if (activeTab !== tab) setActiveTab(tab)
	}
	const [isActive, setActive] = useState(false)
	const grdvwtgl = () => {
		setActive(!isActive)
	}
	const [isActive1, setActive1] = useState(false)
	const lstvwtgl = () => {
		setActive1(!isActive1)
	}
	return (
		<Fragment>
			<div className='cmndv'>
				<InnerHeader />
				<div className='cntldvinr'>
					<div class='SwpMainPg'>
						<div class='container-fluid container-1450'>
							<div class='row mb-5'>
								<div class='col-lg-6 mx-auto'>
									<ul class='SwpLqtTitl'>
										<li>
											<a href=''>Swap</a>
										</li>
										<li>
											<a href='' class='active'>
												Liquidity
											</a>
										</li>
									</ul>
								</div>
							</div>
							<div class='row'>
								<div class='col-xl-8 mb-3'>
									<div class='LiqdtySec'>
										<div class='CmmnWhtBg BrdRdsTop '>
											<div class='LqpoolHdd LqBrdBttm'>
												<div class='LqHddSec'>
													<div class='SwpHdd d-flex'>
														<div class='RdoBtnSec mr-4'>
															<div class='radio'>
																<input
																	id='radio-1'
																	name='radio'
																	type='radio'
																	defaultChecked='checked'
																/>
																<label for='radio-1' class='radio-label'>
																	Pools
																</label>
															</div>
														</div>
														<div class='RdoBtnSec'>
															<div class='radio'>
																<input id='radio-2' name='radio' type='radio' />
																<label for='radio-2' class='radio-label'>
																	Farming{' '}
																</label>
															</div>
														</div>
													</div>
													<div class='GrdLstIcon'>
														<div class='GrdLstSec'>
															<ul class='d-flex mb-0'>
																<li>
																	<a>
																		<img
																			src={grdvirwImg}
																			class='img-fluid mr-3'
																			onClick={() => setActive(false)}
																		/>
																	</a>
																</li>
																<li>
																	<a>
																		<img
																			src={lstvirwImg}
																			class='img-fluid'
																			onClick={() => setActive(true)}
																		/>
																	</a>
																</li>
															</ul>
														</div>
													</div>
												</div>
											</div>
											<div class='LqFltrSec p-3'>
												<div class='row'>
													<div class='col mb-2'>
														<div class='LqSrchSec LftSrchIcon'>
															<input
																class='form-control'
																type='text'
																placeholder='Search pools by token name, pool contracr address'
															/>
														</div>
													</div>
													<div class='col-lg-6 '>
														<div class='d-flex flex-wrap justify-content-lg-end'>
															<div class='LiveFinshSec mr-4 mb-2'>
																<ul class='nav nav-tabs' role='tablist'>
																	<li class='nav-item'>
																		<a
																			class='nav-link'
																			data-toggle='tab'
																			href='#tabs-1'
																			role='tab'
																			aria-selected='false'
																		>
																			Live
																		</a>
																	</li>
																	<li class='nav-item'>
																		<a
																			class='nav-link active'
																			data-toggle='tab'
																			href='#tabs-2'
																			role='tab'
																			aria-selected='true'
																		>
																			Finished
																		</a>
																	</li>
																</ul>
															</div>
															<div class='SlctTknBtnSec'>
																<select class='custom-select'>
																	<option selected>Popular pools</option>
																	<option value='1'>Newly created</option>
																	<option value='2'>Volume</option>
																	<option value='3'>APR%</option>
																	<option value='3'>Popular</option>
																</select>
															</div>
														</div>
													</div>
												</div>
											</div>
										</div>
										<div
											class={
												isActive1
													? 'LiqCrdSec mt-4 CusScrll AllTknsSec LiqCrdSecHgt lsdvwdv'
													: 'LiqCrdSec mt-4 CusScrll AllTknsSec LiqCrdSecHgt'
											}
										>
											{!isActive && (
												<div className='row mx-0 grdvw'>
													<div class='col-lg-4 col-md-6 mb-4'>
														<div class='PoolsCrdItm'>
															<div class='PoolsHddSec '>
																<div class='PoolsHddCnt'>
																	<h6>Provide Liquidity</h6>
																	<h4>QBANK-USDT Pool</h4>
																</div>
																<div class='PoolsHddImgs'>
																	<div class='PolsTknImg PolsTknImg1'>
																		<img src={liqpooltkn1} class='img-fluid' />
																	</div>
																	<div class='PolsTknImg PolsTknImg2'>
																		<img src={liqpooltkn2} class='img-fluid' />
																	</div>
																</div>
															</div>
															<div class='PoolsDtlsCnt'>
																<h6>Reward</h6>
																<h4>QSDT LP Token</h4>
															</div>
															<div class='PoolsDtlsCnt'>
																<h6>Reward APY</h6>
																<h4>8.02%</h4>
															</div>
															<div class='PoolsDtlsCnt'>
																<h6>Total Value Locked</h6>
																<h4>$271.65M</h4>
															</div>
															<div class='CnctWlltBtnSec'>
																<button
																	class='btn CnctWlltBtn w-100 Mnht-42 AddLiqt'
																	type='button'
																>
																	<img
																		src={addplusiconImg}
																		class='img-fluid mr-2'
																	/>{' '}
																	Add Liquidity
																</button>
															</div>
														</div>
													</div>
													<div class='col-lg-4 col-md-6 mb-4'>
														<div class='PoolsCrdItm'>
															<div class='PoolsHddSec '>
																<div class='PoolsHddCnt'>
																	<h6>Provide Liquidity</h6>
																	<h4>DAI-USD Pool</h4>
																</div>
																<div class='PoolsHddImgs'>
																	<div class='PolsTknImg PolsTknImg1'>
																		<img src={liqpooltkn3} class='img-fluid' />
																	</div>
																	<div class='PolsTknImg PolsTknImg2'>
																		<img src={liqpooltkn4} class='img-fluid' />
																	</div>
																</div>
															</div>
															<div class='PoolsDtlsCnt'>
																<h6>Reward</h6>
																<h4>QSDT LP Token</h4>
															</div>
															<div class='PoolsDtlsCnt'>
																<h6>Reward APY</h6>
																<h4>8.02%</h4>
															</div>
															<div class='PoolsDtlsCnt'>
																<h6>Total Value Locked</h6>
																<h4>$271.65M</h4>
															</div>
															<div class='CnctWlltBtnSec'>
																<button
																	class='btn CnctWlltBtn w-100 Mnht-42 AddLiqt'
																	type='button'
																>
																	<img
																		src={addplusiconImg}
																		class='img-fluid mr-2'
																	/>{' '}
																	Add Liquidity
																</button>
															</div>
														</div>
													</div>
													<div class='col-lg-4 col-md-6 mb-4'>
														<div class='PoolsCrdItm'>
															<div class='PoolsHddSec '>
																<div class='PoolsHddCnt'>
																	<h6>Provide Liquidity</h6>
																	<h4>SAND-USDT Pool</h4>
																</div>
																<div class='PoolsHddImgs'>
																	<div class='PolsTknImg PolsTknImg1'>
																		<img src={liqpooltkn1} class='img-fluid' />
																	</div>
																	<div class='PolsTknImg PolsTknImg2'>
																		<img src={liqpooltkn5} class='img-fluid' />
																	</div>
																</div>
															</div>
															<div class='PoolsDtlsCnt'>
																<h6>Reward</h6>
																<h4>QSDT LP Token</h4>
															</div>
															<div class='PoolsDtlsCnt'>
																<h6>Reward APY</h6>
																<h4>8.02%</h4>
															</div>
															<div class='PoolsDtlsCnt'>
																<h6>Total Value Locked</h6>
																<h4>$271.65M</h4>
															</div>
															<div class='CnctWlltBtnSec'>
																<button
																	class='btn CnctWlltBtn w-100 Mnht-42 AddLiqt'
																	type='button'
																>
																	<img
																		src={addplusiconImg}
																		class='img-fluid mr-2'
																	/>{' '}
																	Add Liquidity
																</button>
															</div>
														</div>
													</div>
													<div class='col-lg-4 col-md-6 mb-4'>
														<div class='PoolsCrdItm'>
															<div class='PoolsHddSec '>
																<div class='PoolsHddCnt'>
																	<h6>Provide Liquidity</h6>
																	<h4>ETH-USDT Pool</h4>
																</div>
																<div class='PoolsHddImgs'>
																	<div class='PolsTknImg PolsTknImg1'>
																		<img src={liqpooltkn1} class='img-fluid' />
																	</div>
																	<div class='PolsTknImg PolsTknImg2'>
																		<img src={liqpooltkn6} class='img-fluid' />
																	</div>
																</div>
															</div>
															<div class='PoolsDtlsCnt'>
																<h6>Reward</h6>
																<h4>QSDT LP Token</h4>
															</div>
															<div class='PoolsDtlsCnt'>
																<h6>Reward APY</h6>
																<h4>8.02%</h4>
															</div>
															<div class='PoolsDtlsCnt'>
																<h6>Total Value Locked</h6>
																<h4>$271.65M</h4>
															</div>
															<div class='CnctWlltBtnSec'>
																<button
																	class='btn CnctWlltBtn w-100 Mnht-42 AddLiqt'
																	type='button'
																>
																	<img
																		src={addplusiconImg}
																		class='img-fluid mr-2'
																	/>{' '}
																	Add Liquidity
																</button>
															</div>
														</div>
													</div>
													<div class='col-lg-4 col-md-6 mb-4'>
														<div class='PoolsCrdItm'>
															<div class='PoolsHddSec '>
																<div class='PoolsHddCnt'>
																	<h6>Provide Liquidity</h6>
																	<h4>RSV-USDC Pool</h4>
																</div>
																<div class='PoolsHddImgs'>
																	<div class='PolsTknImg PolsTknImg1'>
																		<img src={liqpooltkn3} class='img-fluid' />
																	</div>
																	<div class='PolsTknImg PolsTknImg2'>
																		<img src={liqpooltkn7} class='img-fluid' />
																	</div>
																</div>
															</div>
															<div class='PoolsDtlsCnt'>
																<h6>Reward</h6>
																<h4>QSDT LP Token</h4>
															</div>
															<div class='PoolsDtlsCnt'>
																<h6>Reward APY</h6>
																<h4>8.02%</h4>
															</div>
															<div class='PoolsDtlsCnt'>
																<h6>Total Value Locked</h6>
																<h4>$271.65M</h4>
															</div>
															<div class='CnctWlltBtnSec'>
																<button
																	class='btn CnctWlltBtn w-100 Mnht-42 AddLiqt'
																	type='button'
																>
																	<img
																		src={addplusiconImg}
																		class='img-fluid mr-2'
																	/>{' '}
																	Add Liquidity
																</button>
															</div>
														</div>
													</div>
													<div class='col-lg-4 col-md-6 mb-4'>
														<div class='PoolsCrdItm'>
															<div class='PoolsHddSec '>
																<div class='PoolsHddCnt'>
																	<h6>Provide Liquidity</h6>
																	<h4>STMX-ETH Pool</h4>
																</div>
																<div class='PoolsHddImgs'>
																	<div class='PolsTknImg PolsTknImg1'>
																		<img src={liqpooltkn6} class='img-fluid' />
																	</div>
																	<div class='PolsTknImg PolsTknImg2'>
																		<img src={liqpooltkn8} class='img-fluid' />
																	</div>
																</div>
															</div>
															<div class='PoolsDtlsCnt'>
																<h6>Reward</h6>
																<h4>QSDT LP Token</h4>
															</div>
															<div class='PoolsDtlsCnt'>
																<h6>Reward APY</h6>
																<h4>8.02%</h4>
															</div>
															<div class='PoolsDtlsCnt'>
																<h6>Total Value Locked</h6>
																<h4>$271.65M</h4>
															</div>
															<div class='CnctWlltBtnSec'>
																<button
																	class='btn CnctWlltBtn w-100 Mnht-42 AddLiqt'
																	type='button'
																>
																	<img
																		src={addplusiconImg}
																		class='img-fluid mr-2'
																	/>{' '}
																	Add Liquidity
																</button>
															</div>
														</div>
													</div>
													<div class='col-lg-4 col-md-6 mb-4'>
														<div class='PoolsCrdItm'>
															<div class='PoolsHddSec '>
																<div class='PoolsHddCnt'>
																	<h6>Provide Liquidity</h6>
																	<h4>ETH-KNC Pool</h4>
																</div>
																<div class='PoolsHddImgs'>
																	<div class='PolsTknImg PolsTknImg1'>
																		<img src={liqpooltkn9} class='img-fluid' />
																	</div>
																	<div class='PolsTknImg PolsTknImg2'>
																		<img src={liqpooltkn6} class='img-fluid' />
																	</div>
																</div>
															</div>
															<div class='PoolsDtlsCnt'>
																<h6>Reward</h6>
																<h4>QSDT LP Token</h4>
															</div>
															<div class='PoolsDtlsCnt'>
																<h6>Reward APY</h6>
																<h4>8.02%</h4>
															</div>
															<div class='PoolsDtlsCnt'>
																<h6>Total Value Locked</h6>
																<h4>$271.65M</h4>
															</div>
															<div class='CnctWlltBtnSec'>
																<button
																	class='btn CnctWlltBtn w-100 Mnht-42 AddLiqt'
																	type='button'
																>
																	<img
																		src={addplusiconImg}
																		class='img-fluid mr-2'
																	/>{' '}
																	Add Liquidity
																</button>
															</div>
														</div>
													</div>
													<div class='col-lg-4 col-md-6 mb-4'>
														<div class='PoolsCrdItm'>
															<div class='PoolsHddSec '>
																<div class='PoolsHddCnt'>
																	<h6>Provide Liquidity</h6>
																	<h4>XTK-ETH Pool</h4>
																</div>
																<div class='PoolsHddImgs'>
																	<div class='PolsTknImg PolsTknImg1'>
																		<img src={liqpooltkn6} class='img-fluid' />
																	</div>
																	<div class='PolsTknImg PolsTknImg2'>
																		<img src={liqpooltkn10} class='img-fluid' />
																	</div>
																</div>
															</div>
															<div class='PoolsDtlsCnt'>
																<h6>Reward</h6>
																<h4>QSDT LP Token</h4>
															</div>
															<div class='PoolsDtlsCnt'>
																<h6>Reward APY</h6>
																<h4>8.02%</h4>
															</div>
															<div class='PoolsDtlsCnt'>
																<h6>Total Value Locked</h6>
																<h4>$271.65M</h4>
															</div>
															<div class='CnctWlltBtnSec'>
																<button
																	class='btn CnctWlltBtn w-100 Mnht-42 AddLiqt'
																	type='button'
																>
																	<img
																		src={addplusiconImg}
																		class='img-fluid mr-2'
																	/>{' '}
																	Add Liquidity
																</button>
															</div>
														</div>
													</div>
													<div class='col-lg-4 col-md-6 mb-4'>
														<div class='PoolsCrdItm'>
															<div class='PoolsHddSec '>
																<div class='PoolsHddCnt'>
																	<h6>Provide Liquidity</h6>
																	<h4>EVRY-USDT Pool</h4>
																</div>
																<div class='PoolsHddImgs'>
																	<div class='PolsTknImg PolsTknImg1'>
																		<img src={liqpooltkn1} class='img-fluid' />
																	</div>
																	<div class='PolsTknImg PolsTknImg2'>
																		<img src={liqpooltkn11} class='img-fluid' />
																	</div>
																</div>
															</div>
															<div class='PoolsDtlsCnt'>
																<h6>Reward</h6>
																<h4>QSDT LP Token</h4>
															</div>
															<div class='PoolsDtlsCnt'>
																<h6>Reward APY</h6>
																<h4>8.02%</h4>
															</div>
															<div class='PoolsDtlsCnt'>
																<h6>Total Value Locked</h6>
																<h4>$271.65M</h4>
															</div>
															<div class='CnctWlltBtnSec'>
																<button
																	class='btn CnctWlltBtn w-100 Mnht-42 AddLiqt'
																	type='button'
																>
																	<img
																		src={addplusiconImg}
																		class='img-fluid mr-2'
																	/>{' '}
																	Add Liquidity
																</button>
															</div>
														</div>
													</div>
												</div>
											)}
											<div className='row mx-0 d-none'>
												<div class='col-12'>
													<div class='NoActivsCnt text-center NoActivTop'>
														<h4>Currently, no active pools are available</h4>
														<p class='col-xl-5 mx-auto'>
															Create a new pool to provide liquidity there and
															earns from it.
														</p>
														<div class='AllTknsCntRt'>
															<button class='btn BtnImprt mx-auto px-4' type=''>
																Create Pool
															</button>
														</div>
													</div>
												</div>
											</div>
											{isActive && (
												<div className='row mx-0 lstvw'>
													<div class='col-12'>
														<div class='PoolsLstItm mb-3'>
															<div class='PoolsLstBox'>
																<div class='row'>
																	<div class='col-lg-1'>
																		<div class='PoolsHddImgs LstVwHddImg'>
																			<div class='PolsTknImg PolsTknImg1'>
																				<img
																					src={liqpooltkn1}
																					class='img-fluid'
																				/>
																			</div>
																			<div class='PolsTknImg PolsTknImg2'>
																				<img
																					src={liqpooltkn2}
																					class='img-fluid'
																				/>
																			</div>
																		</div>
																	</div>
																	<div class='col-lg-11'>
																		<div class='PoolsLstCntSec mb-2'>
																			<div class='row'>
																				<div class='col-md-4'>
																					<div class='PoolsLstCnt'>
																						<h4>QBANK/USDT</h4>
																						<h6>1 QBANK = 10.32 USDT</h6>
																						<span>BNB Smart Chain</span>
																						<span class='LstCntDot'></span>
																						<span>200 days old</span>
																					</div>
																				</div>
																				<div class='col-md-8'>
																					<div class='VlTvFeApSec'>
																						<div class='VlTvFeApCnt'>
																							<h6>Volume (24h)</h6>
																							<h4>$271.65K</h4>
																						</div>
																						<div class='VlTvFeApCnt'>
																							<h6>TVL</h6>
																							<h4>$271.65MK</h4>
																						</div>
																						<div class='VlTvFeApCnt'>
																							<h6>Fee (24h)</h6>
																							<h4>$0.82</h4>
																						</div>
																						<div class='VlTvFeApCnt'>
																							<h6>APY (%)</h6>
																							<h4>8.02%</h4>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																		<div class='LstVwPolBtn'>
																			<div class='CpyViewSec'>
																				<div class='CpyCnt mr-4'>
																					<span>
																						Copy pool contract{' '}
																						<a href='' class='ml-2'>
																							<img src={lstcpyicon1} />
																						</a>
																					</span>
																				</div>
																				<div class='CpyCnt'>
																					<span>
																						View on block explorer{' '}
																						<a href='' class='ml-2'>
																							<img src={lstcpyicon1} />
																						</a>
																					</span>
																				</div>
																			</div>
																			<div class=''>
																				<button
																					class='btn CnctWlltBtn w-100 Mnht-36 AddLiqt px-3'
																					type='button'
																				>
																					<img
																						src={addplusiconImg}
																						class='img-fluid mr-2'
																					/>{' '}
																					Add Liquidity
																				</button>
																			</div>
																		</div>
																	</div>
																</div>
															</div>

															<div class='CllpseSec p-0 LstCllpse'>
																<div class='card'>
																	<Collapsible>
																		<div id='Lstviw1'>
																			<div class='LiqLstChrtSec'>
																				<div class='row'>
																					<div class='col-md-8'>
																						<div class='LwChrtSec'>
																							<div class='ChrtHddSec mb-4'>
																								<div class='ChrtTabs LiveFinshSec d-flex'>
																									<Nav tabs className='mr-3'>
																										<NavItem>
																											<NavLink
																												className={classnames({
																													active:
																														activeTab === '1'
																												})}
																												onClick={() => {
																													toggle('1')
																												}}
																											>
																												Liquidity
																											</NavLink>
																										</NavItem>
																										<NavItem>
																											<NavLink
																												className={classnames({
																													active:
																														activeTab === '2'
																												})}
																												onClick={() => {
																													toggle('2')
																												}}
																											>
																												Volume
																											</NavLink>
																										</NavItem>
																										<NavItem>
																											<NavLink
																												className={classnames({
																													active:
																														activeTab === '3'
																												})}
																												onClick={() => {
																													toggle('3')
																												}}
																											>
																												TVL
																											</NavLink>
																										</NavItem>
																										<NavItem>
																											<NavLink
																												className={classnames({
																													active:
																														activeTab === '4'
																												})}
																												onClick={() => {
																													toggle('4')
																												}}
																											>
																												Fees
																											</NavLink>
																										</NavItem>
																									</Nav>
																									<div class='SlctTknBtnSec LwArrwSlct'>
																										<select class='custom-select'>
																											<option selected=''>
																												All time
																											</option>
																											<option value='1'>
																												4 Hours
																											</option>
																											<option value='2'>
																												1 Days
																											</option>
																											<option value='3'>
																												3 Days
																											</option>
																											<option value='3'>
																												1 Week
																											</option>
																											<option value='3'>
																												1 Month
																											</option>
																											<option value='3'>
																												3 Month
																											</option>
																											<option value='3'>
																												6 Month
																											</option>
																											<option value='3'>
																												All-time
																											</option>
																										</select>
																									</div>
																								</div>
																								<div class='ChrtFees'>
																									<h4>$750.05</h4>
																									<h5>All time fees</h5>
																								</div>
																							</div>
																							<TabContent activeTab={activeTab}>
																								<TabPane tabId='1'>
																									<div class='LwChartImg'>
																										<img
																											src={lstcht1}
																											class='img-fluid mx-auto d-block'
																										/>
																									</div>
																								</TabPane>
																								<TabPane tabId='2'>
																									<div class='LwChartImg'>
																										<img
																											src={lstcht2}
																											class='img-fluid mx-auto d-block'
																										/>
																									</div>
																								</TabPane>
																								<TabPane tabId='3'>
																									<div class='LwChartImg'>
																										<img
																											src={lstcht3}
																											class='img-fluid mx-auto d-block'
																										/>
																									</div>
																								</TabPane>
																								<TabPane tabId='4'>
																									<div class='LwChartImg'>
																										<img
																											src={lstcht4}
																											class='img-fluid mx-auto d-block'
																										/>
																									</div>
																								</TabPane>
																							</TabContent>
																						</div>
																					</div>
																					<div class='col-md-4'>
																						<div class='LstPoolsDtls'>
																							<div class='LstPoolsDtlsHdd mb-3 pb-1'>
																								<h4>Pool Details</h4>
																							</div>
																							<div class='LwPlsDtlsCnt'>
																								<h5>Pool Name:</h5>
																								<h4>Qbank-USDT Pool</h4>
																							</div>
																							<div class='LwPlsDtlsCnt'>
																								<h5>Ratio:</h5>
																								<h4>40% QBANK : 60% USDT</h4>
																							</div>
																							<div class='LwPlsDtlsCnt'>
																								<h5>Contract Addr. :</h5>
																								<h4>0xa38a...3020</h4>
																							</div>
																							<div class='LwPlsDtlsCnt'>
																								<h5>Pooled Qbank:</h5>
																								<h4>20K QBANK</h4>
																							</div>
																							<div class='LwPlsDtlsCnt'>
																								<h5>Pooled Tether:</h5>
																								<h4>4128000 USDT</h4>
																							</div>
																							<div class='LwPlsDtlsCnt'>
																								<h5>Swap Fees:</h5>
																								<h4>0.3%</h4>
																							</div>
																							<div class='LwPlsDtlsCnt mb-0'>
																								<h5>Creation Date: </h5>
																								<h4>03 Jun 2022</h4>
																							</div>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</Collapsible>
																</div>
															</div>
														</div>
														<div class='PoolsLstItm mb-3'>
															<div class='PoolsLstBox'>
																<div class='row'>
																	<div class='col-lg-1'>
																		<div class='PoolsHddImgs LstVwHddImg'>
																			<div class='PolsTknImg PolsTknImg1'>
																				<img
																					src={liqpooltkn1}
																					class='img-fluid'
																				/>
																			</div>
																			<div class='PolsTknImg PolsTknImg2'>
																				<img
																					src={liqpooltkn6}
																					class='img-fluid'
																				/>
																			</div>
																		</div>
																	</div>
																	<div class='col-lg-11'>
																		<div class='PoolsLstCntSec mb-2'>
																			<div class='row'>
																				<div class='col-md-4'>
																					<div class='PoolsLstCnt'>
																						<h4>ETH/USDT</h4>
																						<h6>1 ETH = 1,318.02 USDT</h6>
																						<span>BNB Smart Chain</span>
																						<span class='LstCntDot'></span>
																						<span>200 days old</span>
																					</div>
																				</div>
																				<div class='col-md-8'>
																					<div class='VlTvFeApSec'>
																						<div class='VlTvFeApCnt'>
																							<h6>Volume (24h)</h6>
																							<h4>$271.65K</h4>
																						</div>
																						<div class='VlTvFeApCnt'>
																							<h6>TVL</h6>
																							<h4>$271.65MK</h4>
																						</div>
																						<div class='VlTvFeApCnt'>
																							<h6>Fee (24h)</h6>
																							<h4>$0.82</h4>
																						</div>
																						<div class='VlTvFeApCnt'>
																							<h6>APY (%)</h6>
																							<h4>8.02%</h4>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																		<div class='LstVwPolBtn'>
																			<div class='CpyViewSec'>
																				<div class='CpyCnt mr-4'>
																					<span>
																						Copy pool contract{' '}
																						<a href='' class='ml-2'>
																							<img src={lstcpyicon1} />
																						</a>
																					</span>
																				</div>
																				<div class='CpyCnt'>
																					<span>
																						View on block explorer{' '}
																						<a href='' class='ml-2'>
																							<img src={lstcpyicon1} />
																						</a>
																					</span>
																				</div>
																			</div>
																			<div class=''>
																				<button
																					class='btn CnctWlltBtn w-100 Mnht-36 AddLiqt px-3'
																					type='button'
																				>
																					<img
																						src={addplusiconImg}
																						class='img-fluid mr-2'
																					/>{' '}
																					Add Liquidity
																				</button>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															<div class='CllpseSec p-0 LstCllpse'>
																<div class='card'>
																	<Collapsible>
																		<div class='LiqLstChrtSec'>
																			<div class='row'>
																				<div class='col-md-8'>
																					<div class='LwChrtSec'>
																						<div class='ChrtHddSec mb-4'>
																							<div class='ChrtTabs LiveFinshSec d-flex'>
																								<Nav tabs className='mr-3'>
																									<NavItem>
																										<NavLink
																											className={classnames({
																												active:
																													activeTab === '1'
																											})}
																											onClick={() => {
																												toggle('1')
																											}}
																										>
																											Liquidity
																										</NavLink>
																									</NavItem>
																									<NavItem>
																										<NavLink
																											className={classnames({
																												active:
																													activeTab === '2'
																											})}
																											onClick={() => {
																												toggle('2')
																											}}
																										>
																											Volume
																										</NavLink>
																									</NavItem>
																									<NavItem>
																										<NavLink
																											className={classnames({
																												active:
																													activeTab === '3'
																											})}
																											onClick={() => {
																												toggle('3')
																											}}
																										>
																											TVL
																										</NavLink>
																									</NavItem>
																									<NavItem>
																										<NavLink
																											className={classnames({
																												active:
																													activeTab === '4'
																											})}
																											onClick={() => {
																												toggle('4')
																											}}
																										>
																											Fees
																										</NavLink>
																									</NavItem>
																								</Nav>
																								<div class='SlctTknBtnSec LwArrwSlct'>
																									<select class='custom-select'>
																										<option selected=''>
																											All time
																										</option>
																										<option value='1'>
																											4 Hours
																										</option>
																										<option value='2'>
																											1 Days
																										</option>
																										<option value='3'>
																											3 Days
																										</option>
																										<option value='3'>
																											1 Week
																										</option>
																										<option value='3'>
																											1 Month
																										</option>
																										<option value='3'>
																											3 Month
																										</option>
																										<option value='3'>
																											6 Month
																										</option>
																										<option value='3'>
																											All-time
																										</option>
																									</select>
																								</div>
																							</div>
																							<div class='ChrtFees'>
																								<h4>$750.05</h4>
																								<h5>All time fees</h5>
																							</div>
																						</div>
																						<TabContent activeTab={activeTab}>
																							<TabPane tabId='1'>
																								<div class='LwChartImg'>
																									<img
																										src={lstcht1}
																										class='img-fluid mx-auto d-block'
																									/>
																								</div>
																							</TabPane>
																							<TabPane tabId='2'>
																								<div class='LwChartImg'>
																									<img
																										src={lstcht2}
																										class='img-fluid mx-auto d-block'
																									/>
																								</div>
																							</TabPane>
																							<TabPane tabId='3'>
																								<div class='LwChartImg'>
																									<img
																										src={lstcht3}
																										class='img-fluid mx-auto d-block'
																									/>
																								</div>
																							</TabPane>
																							<TabPane tabId='4'>
																								<div class='LwChartImg'>
																									<img
																										src={lstcht4}
																										class='img-fluid mx-auto d-block'
																									/>
																								</div>
																							</TabPane>
																						</TabContent>
																					</div>
																				</div>
																				<div class='col-md-4'>
																					<div class='LstPoolsDtls'>
																						<div class='LstPoolsDtlsHdd mb-3 pb-1'>
																							<h4>Pool Details</h4>
																						</div>
																						<div class='LwPlsDtlsCnt'>
																							<h5>Pool Name:</h5>
																							<h4>Qbank-USDT Pool</h4>
																						</div>
																						<div class='LwPlsDtlsCnt'>
																							<h5>Ratio:</h5>
																							<h4>40% QBANK : 60% USDT</h4>
																						</div>
																						<div class='LwPlsDtlsCnt'>
																							<h5>Contract Addr. :</h5>
																							<h4>0xa38a...3020</h4>
																						</div>
																						<div class='LwPlsDtlsCnt'>
																							<h5>Pooled Qbank:</h5>
																							<h4>20K QBANK</h4>
																						</div>
																						<div class='LwPlsDtlsCnt'>
																							<h5>Pooled Tether:</h5>
																							<h4>4128000 USDT</h4>
																						</div>
																						<div class='LwPlsDtlsCnt'>
																							<h5>Swap Fees:</h5>
																							<h4>0.3%</h4>
																						</div>
																						<div class='LwPlsDtlsCnt mb-0'>
																							<h5>Creation Date: </h5>
																							<h4>03 Jun 2022</h4>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</Collapsible>
																</div>
															</div>
														</div>
														<div class='PoolsLstItm mb-3'>
															<div class='PoolsLstBox'>
																<div class='row'>
																	<div class='col-lg-1'>
																		<div class='PoolsHddImgs LstVwHddImg'>
																			<div class='PolsTknImg PolsTknImg1'>
																				<img
																					src={liqpooltkn7}
																					class='img-fluid'
																				/>
																			</div>
																			<div class='PolsTknImg PolsTknImg2'>
																				<img
																					src={liqpooltkn3}
																					class='img-fluid'
																				/>
																			</div>
																		</div>
																	</div>
																	<div class='col-lg-11'>
																		<div class='PoolsLstCntSec mb-2'>
																			<div class='row'>
																				<div class='col-md-4'>
																					<div class='PoolsLstCnt'>
																						<h4>RSV/USDC</h4>
																						<h6>1 RSV = 0.99 USDC</h6>
																						<span>BNB Smart Chain</span>
																						<span class='LstCntDot'></span>
																						<span>158 days old</span>
																					</div>
																				</div>
																				<div class='col-md-8'>
																					<div class='VlTvFeApSec'>
																						<div class='VlTvFeApCnt'>
																							<h6>Volume (24h)</h6>
																							<h4>$271.65K</h4>
																						</div>
																						<div class='VlTvFeApCnt'>
																							<h6>TVL</h6>
																							<h4>$271.65MK</h4>
																						</div>
																						<div class='VlTvFeApCnt'>
																							<h6>Fee (24h)</h6>
																							<h4>$0.82</h4>
																						</div>
																						<div class='VlTvFeApCnt'>
																							<h6>APY (%)</h6>
																							<h4>8.02%</h4>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																		<div class='LstVwPolBtn'>
																			<div class='CpyViewSec'>
																				<div class='CpyCnt mr-4'>
																					<span>
																						Copy pool contract{' '}
																						<a href='' class='ml-2'>
																							<img src={lstcpyicon1} />
																						</a>
																					</span>
																				</div>
																				<div class='CpyCnt'>
																					<span>
																						View on block explorer{' '}
																						<a href='' class='ml-2'>
																							<img src={lstcpyicon1} />
																						</a>
																					</span>
																				</div>
																			</div>
																			<div class=''>
																				<button
																					class='btn CnctWlltBtn w-100 Mnht-36 AddLiqt px-3'
																					type='button'
																				>
																					<img
																						src={addplusiconImg}
																						class='img-fluid mr-2'
																					/>{' '}
																					Add Liquidity
																				</button>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															<div class='CllpseSec p-0 LstCllpse'>
																<div class='card'>
																	<Collapsible>
																		<div class='LiqLstChrtSec'>
																			<div class='row'>
																				<div class='col-md-8'>
																					<div class='LwChrtSec'>
																						<div class='ChrtHddSec mb-4'>
																							<div class='ChrtTabs LiveFinshSec d-flex'>
																								<Nav tabs className='mr-3'>
																									<NavItem>
																										<NavLink
																											className={classnames({
																												active:
																													activeTab === '1'
																											})}
																											onClick={() => {
																												toggle('1')
																											}}
																										>
																											Liquidity
																										</NavLink>
																									</NavItem>
																									<NavItem>
																										<NavLink
																											className={classnames({
																												active:
																													activeTab === '2'
																											})}
																											onClick={() => {
																												toggle('2')
																											}}
																										>
																											Volume
																										</NavLink>
																									</NavItem>
																									<NavItem>
																										<NavLink
																											className={classnames({
																												active:
																													activeTab === '3'
																											})}
																											onClick={() => {
																												toggle('3')
																											}}
																										>
																											TVL
																										</NavLink>
																									</NavItem>
																									<NavItem>
																										<NavLink
																											className={classnames({
																												active:
																													activeTab === '4'
																											})}
																											onClick={() => {
																												toggle('4')
																											}}
																										>
																											Fees
																										</NavLink>
																									</NavItem>
																								</Nav>
																								<div class='SlctTknBtnSec LwArrwSlct'>
																									<select class='custom-select'>
																										<option selected=''>
																											All time
																										</option>
																										<option value='1'>
																											4 Hours
																										</option>
																										<option value='2'>
																											1 Days
																										</option>
																										<option value='3'>
																											3 Days
																										</option>
																										<option value='3'>
																											1 Week
																										</option>
																										<option value='3'>
																											1 Month
																										</option>
																										<option value='3'>
																											3 Month
																										</option>
																										<option value='3'>
																											6 Month
																										</option>
																										<option value='3'>
																											All-time
																										</option>
																									</select>
																								</div>
																							</div>
																							<div class='ChrtFees'>
																								<h4>$750.05</h4>
																								<h5>All time fees</h5>
																							</div>
																						</div>
																						<TabContent activeTab={activeTab}>
																							<TabPane tabId='1'>
																								<div class='LwChartImg'>
																									<img
																										src={lstcht1}
																										class='img-fluid mx-auto d-block'
																									/>
																								</div>
																							</TabPane>
																							<TabPane tabId='2'>
																								<div class='LwChartImg'>
																									<img
																										src={lstcht2}
																										class='img-fluid mx-auto d-block'
																									/>
																								</div>
																							</TabPane>
																							<TabPane tabId='3'>
																								<div class='LwChartImg'>
																									<img
																										src={lstcht3}
																										class='img-fluid mx-auto d-block'
																									/>
																								</div>
																							</TabPane>
																							<TabPane tabId='4'>
																								<div class='LwChartImg'>
																									<img
																										src={lstcht4}
																										class='img-fluid mx-auto d-block'
																									/>
																								</div>
																							</TabPane>
																						</TabContent>
																					</div>
																				</div>
																				<div class='col-md-4'>
																					<div class='LstPoolsDtls'>
																						<div class='LstPoolsDtlsHdd mb-3 pb-1'>
																							<h4>Pool Details</h4>
																						</div>
																						<div class='LwPlsDtlsCnt'>
																							<h5>Pool Name:</h5>
																							<h4>Qbank-USDT Pool</h4>
																						</div>
																						<div class='LwPlsDtlsCnt'>
																							<h5>Ratio:</h5>
																							<h4>40% QBANK : 60% USDT</h4>
																						</div>
																						<div class='LwPlsDtlsCnt'>
																							<h5>Contract Addr. :</h5>
																							<h4>0xa38a...3020</h4>
																						</div>
																						<div class='LwPlsDtlsCnt'>
																							<h5>Pooled Qbank:</h5>
																							<h4>20K QBANK</h4>
																						</div>
																						<div class='LwPlsDtlsCnt'>
																							<h5>Pooled Tether:</h5>
																							<h4>4128000 USDT</h4>
																						</div>
																						<div class='LwPlsDtlsCnt'>
																							<h5>Swap Fees:</h5>
																							<h4>0.3%</h4>
																						</div>
																						<div class='LwPlsDtlsCnt mb-0'>
																							<h5>Creation Date: </h5>
																							<h4>03 Jun 2022</h4>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</Collapsible>
																</div>
															</div>
														</div>
														<div class='PoolsLstItm mb-3'>
															<div class='PoolsLstBox'>
																<div class='row'>
																	<div class='col-lg-1'>
																		<div class='PoolsHddImgs LstVwHddImg'>
																			<div class='PolsTknImg PolsTknImg1'>
																				<img
																					src={liqpooltkn8}
																					class='img-fluid'
																				/>
																			</div>
																			<div class='PolsTknImg PolsTknImg2'>
																				<img
																					src={liqpooltkn6}
																					class='img-fluid'
																				/>
																			</div>
																		</div>
																	</div>
																	<div class='col-lg-11'>
																		<div class='PoolsLstCntSec mb-2'>
																			<div class='row'>
																				<div class='col-md-4'>
																					<div class='PoolsLstCnt'>
																						<h4>STMX/ETH</h4>
																						<h6>1 STMX = 0.00000505 ETH</h6>
																						<span>BNB Smart Chain</span>
																						<span class='LstCntDot'></span>
																						<span>80 days old</span>
																					</div>
																				</div>
																				<div class='col-md-8'>
																					<div class='VlTvFeApSec'>
																						<div class='VlTvFeApCnt'>
																							<h6>Volume (24h)</h6>
																							<h4>$271.65K</h4>
																						</div>
																						<div class='VlTvFeApCnt'>
																							<h6>TVL</h6>
																							<h4>$271.65MK</h4>
																						</div>
																						<div class='VlTvFeApCnt'>
																							<h6>Fee (24h)</h6>
																							<h4>$0.82</h4>
																						</div>
																						<div class='VlTvFeApCnt'>
																							<h6>APY (%)</h6>
																							<h4>8.02%</h4>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																		<div class='LstVwPolBtn'>
																			<div class='CpyViewSec'>
																				<div class='CpyCnt mr-4'>
																					<span>
																						Copy pool contract{' '}
																						<a href='' class='ml-2'>
																							<img src={lstcpyicon1} />
																						</a>
																					</span>
																				</div>
																				<div class='CpyCnt'>
																					<span>
																						View on block explorer{' '}
																						<a href='' class='ml-2'>
																							<img src={lstcpyicon1} />
																						</a>
																					</span>
																				</div>
																			</div>
																			<div class=''>
																				<button
																					class='btn CnctWlltBtn w-100 Mnht-36 AddLiqt px-3'
																					type='button'
																				>
																					<img
																						src={addplusiconImg}
																						class='img-fluid mr-2'
																					/>{' '}
																					Add Liquidity
																				</button>
																			</div>
																		</div>
																	</div>
																</div>
															</div>
															<div class='CllpseSec p-0 LstCllpse'>
																<div class='card'>
																	<Collapsible>
																		<div class='LiqLstChrtSec'>
																			<div class='row'>
																				<div class='col-md-8'>
																					<div class='LwChrtSec'>
																						<div class='ChrtHddSec mb-4'>
																							<div class='ChrtTabs LiveFinshSec d-flex'>
																								<Nav tabs className='mr-3'>
																									<NavItem>
																										<NavLink
																											className={classnames({
																												active:
																													activeTab === '1'
																											})}
																											onClick={() => {
																												toggle('1')
																											}}
																										>
																											Liquidity
																										</NavLink>
																									</NavItem>
																									<NavItem>
																										<NavLink
																											className={classnames({
																												active:
																													activeTab === '2'
																											})}
																											onClick={() => {
																												toggle('2')
																											}}
																										>
																											Volume
																										</NavLink>
																									</NavItem>
																									<NavItem>
																										<NavLink
																											className={classnames({
																												active:
																													activeTab === '3'
																											})}
																											onClick={() => {
																												toggle('3')
																											}}
																										>
																											TVL
																										</NavLink>
																									</NavItem>
																									<NavItem>
																										<NavLink
																											className={classnames({
																												active:
																													activeTab === '4'
																											})}
																											onClick={() => {
																												toggle('4')
																											}}
																										>
																											Fees
																										</NavLink>
																									</NavItem>
																								</Nav>
																								<div class='SlctTknBtnSec LwArrwSlct'>
																									<select class='custom-select'>
																										<option selected=''>
																											All time
																										</option>
																										<option value='1'>
																											4 Hours
																										</option>
																										<option value='2'>
																											1 Days
																										</option>
																										<option value='3'>
																											3 Days
																										</option>
																										<option value='3'>
																											1 Week
																										</option>
																										<option value='3'>
																											1 Month
																										</option>
																										<option value='3'>
																											3 Month
																										</option>
																										<option value='3'>
																											6 Month
																										</option>
																										<option value='3'>
																											All-time
																										</option>
																									</select>
																								</div>
																							</div>
																							<div class='ChrtFees'>
																								<h4>$750.05</h4>
																								<h5>All time fees</h5>
																							</div>
																						</div>
																						<TabContent activeTab={activeTab}>
																							<TabPane tabId='1'>
																								<div class='LwChartImg'>
																									<img
																										src={lstcht1}
																										class='img-fluid mx-auto d-block'
																									/>
																								</div>
																							</TabPane>
																							<TabPane tabId='2'>
																								<div class='LwChartImg'>
																									<img
																										src={lstcht2}
																										class='img-fluid mx-auto d-block'
																									/>
																								</div>
																							</TabPane>
																							<TabPane tabId='3'>
																								<div class='LwChartImg'>
																									<img
																										src={lstcht3}
																										class='img-fluid mx-auto d-block'
																									/>
																								</div>
																							</TabPane>
																							<TabPane tabId='4'>
																								<div class='LwChartImg'>
																									<img
																										src={lstcht4}
																										class='img-fluid mx-auto d-block'
																									/>
																								</div>
																							</TabPane>
																						</TabContent>
																					</div>
																				</div>
																				<div class='col-md-4'>
																					<div class='LstPoolsDtls'>
																						<div class='LstPoolsDtlsHdd mb-3 pb-1'>
																							<h4>Pool Details</h4>
																						</div>
																						<div class='LwPlsDtlsCnt'>
																							<h5>Pool Name:</h5>
																							<h4>Qbank-USDT Pool</h4>
																						</div>
																						<div class='LwPlsDtlsCnt'>
																							<h5>Ratio:</h5>
																							<h4>40% QBANK : 60% USDT</h4>
																						</div>
																						<div class='LwPlsDtlsCnt'>
																							<h5>Contract Addr. :</h5>
																							<h4>0xa38a...3020</h4>
																						</div>
																						<div class='LwPlsDtlsCnt'>
																							<h5>Pooled Qbank:</h5>
																							<h4>20K QBANK</h4>
																						</div>
																						<div class='LwPlsDtlsCnt'>
																							<h5>Pooled Tether:</h5>
																							<h4>4128000 USDT</h4>
																						</div>
																						<div class='LwPlsDtlsCnt'>
																							<h5>Swap Fees:</h5>
																							<h4>0.3%</h4>
																						</div>
																						<div class='LwPlsDtlsCnt mb-0'>
																							<h5>Creation Date: </h5>
																							<h4>03 Jun 2022</h4>
																						</div>
																					</div>
																				</div>
																			</div>
																		</div>
																	</Collapsible>
																</div>
															</div>
														</div>
													</div>
												</div>
											)}
										</div>
									</div>
								</div>
								<div class='col-xl-4 mb-3'>
									<div class='CmmnWhtBg SwpSec mb-3 PrvdLiqSec'>
										<div class='SwpHddSec mb-4'>
											<div class='SwpHdd'>
												<h4>Provide liquidity</h4>
											</div>
											<div class='SwpSttng d-flex'>
												<div class='AdvSttSec'>
													<div class='tool mr-2' data-tip='Advanced Settings'>
														<a href='' class='text'>
															<img src={sttng1Img} class='img-fluid' />
														</a>
													</div>
												</div>
											</div>
										</div>
										<div class='SellSec'>
											<div class='SwpFlx mb-2 pb-1'>
												<div class='YrSllCnt'>
													<p>Input Amount</p>
												</div>
												<div class='WlltAmnt d-flex align-items-center'>
													<div class='WlltAmntSec mr-2'>
														<h4>
															<img src={swpwltImg} class='mr-2 img-fluid' />0
														</h4>
													</div>
												</div>
											</div>
											<div class='SwpFlx mb-2 pb-1'>
												<div class='TknVlue'>
													<input
														class='form-control'
														type='text'
														value='100.12'
													/>
												</div>
												<div class='SlctTknBtnSec d-flex align-items-center'>
													<div class='WlltAmntBtn mr-2'>
														<button class='btn WlltAmntMxBtn'>MAX</button>
													</div>
													<button
														class='btn SlctTknBtn'
														data-toggle='modal'
														data-target='#SlctTkn'
													>
														Select a token{' '}
														<img src={slcttknImg} class='ml-2 img-fluid' />
													</button>
												</div>
											</div>
											<div class='SwpFlx'>
												<div class='YrSllCnt'>
													<p>$ 0.0</p>
												</div>
												<div class='YrSllCnt'>
													<p>Token Name</p>
												</div>
											</div>
										</div>
										<div class='TknDtls'>
											<div class='UsdBtcAmt mt-1'>
												{/* <h4><a href=""><img src="assets/images/tkn-rfrs-icon.svg" class="mr-2"/></a><span>1USDT</span>  = <span>0.000049BTC</span> </h4> */}
												<h4>------</h4>
											</div>
											<div class='ExchngeBtnSec'>
												<button class='btn ExchngeBtn'>
													<img src={addplusicon1Img} class='img-fluid' />
												</button>
											</div>
										</div>
										<div class='SellSec'>
											<div class='SwpFlx mb-2 pb-1'>
												<div class='YrSllCnt'>
													<p>Input Amount</p>
												</div>
												<div class='WlltAmnt d-flex align-items-center'>
													<div class='WlltAmntSec mr-2'>
														<h4>
															<img src={swpwltImg} class='mr-2 img-fluid' />0
														</h4>
													</div>
												</div>
											</div>
											<div class='SwpFlx mb-2 pb-1'>
												<div class='TknVlue'>
													<input
														class='form-control'
														type='text'
														value='100.12'
													/>
												</div>
												<div class='SlctTknBtnSec d-flex align-items-center'>
													<div class='WlltAmntBtn mr-2'>
														<button class='btn WlltAmntMxBtn'>MAX</button>
													</div>
													<button
														class='btn SlctTknBtn'
														data-toggle='modal'
														data-target='#SlctTkn'
													>
														Select a token{' '}
														<img src={slcttknImg} class='ml-2 img-fluid' />
													</button>
												</div>
											</div>
											<div class='SwpFlx'>
												<div class='YrSllCnt'>
													<p>$ 0.0</p>
												</div>
												<div class='YrSllCnt'>
													<p>Token Name</p>
												</div>
											</div>
										</div>
										<div class='d-flex justify-content-between'>
											<div class='UsdBtcAmt mt-1'>
												<h4>------</h4>
											</div>
											<div class='UsdBtcAmt mt-1'>
												<h4>
													Max slippage: <span>X %</span>{' '}
												</h4>
											</div>
										</div>
										<div class='CnctWlltBtnSec mt-4 pt-1 d-none'>
											<button
												class='btn CnctWlltBtn w-100'
												data-toggle='modal'
												data-target='#CnctWllt'
											>
												Connect Wallet
											</button>
										</div>
										<div class='CnctWlltBtnSec mt-4 pt-1 pb-4'>
											<button class='btn CnctWlltBtn w-100 EnsuffBlBtn'>
												Approve Tokens
											</button>
										</div>
										<div class='CnctWlltBtnSec mt-0 py-0'>
											<button class='btn CnctWlltBtn w-100 EnsuffBlBtn'>
												Supply Liquidity
											</button>
										</div>
									</div>
									<div class='CmmnWhtBg MorInfr PoolShareSec mb-3'>
										<div class='PolShrHdd mb-4'>
											<h4>Your pool share data</h4>
										</div>
										<div class='NoDataCnt MnHght-135 d-none'>
											<p>No data found, supply liquidti first</p>
										</div>
										<div class='PolShrDtls mb-4'>
											<div class='PolShrCntSec'>
												<div class='PolShrCntImg mr-3 TknDropSdw'>
													<img src={liqpooltkn2} class='img-fluid' />
												</div>
												<div class='PolShrCnt'>
													<h6>pooled QBANK</h6>
													<h4>100</h4>
												</div>
											</div>
											<div class='PolShrCntSec'>
												<div class='PolShrCntImg mr-3 TknDropSdw'>
													<img src={liqpooltkn1} class='img-fluid' />
												</div>
												<div class='PolShrCnt'>
													<h6>pooled USDT</h6>
													<h4>1032</h4>
												</div>
											</div>
										</div>
										<div class='PolShrDtls'>
											<div class='PolShrCntSec'>
												<div class='PolShrCntImg mr-3 TknDropSdw'>
													<img src={liqpooltkn12} class='img-fluid' />
												</div>
												<div class='PolShrCnt'>
													<h6>QBANK-USDT LP Tokens</h6>
													<h4>7.12038</h4>
												</div>
											</div>
											<div class='PolShrCntSec'>
												<div class='PolShrCntImg mr-3 TknDropSdw'>
													<img src={liqpooltkn13} class='img-fluid' />
												</div>
												<div class='PolShrCnt'>
													<h6>share of pool</h6>
													<h4>1.3%</h4>
												</div>
											</div>
										</div>
									</div>
									<div class='PlTrnsSec'>
										<div class='CmmnWhtBg MorInfr PoolShareSec mb-2 BrdRdsTop py-3'>
											<div class='d-flex justify-content-between flex-wrap'>
												<div class='PolShrHdd'>
													<h4>Pool Transactions</h4>
												</div>
												<div class='AllTknsCntRt OnOffSwitch'>
													<label class='switch'>
														<input type='checkbox' class='switch-input' />
														<span
															class='switch-label'
															data-on='My'
															data-off='All'
														></span>
														<span class='switch-handle'></span>
													</label>
												</div>
												<div class=''>
													<div class='SlctTknBtnSec'>
														<select class='custom-select'>
															<option selected=''>Adds</option>
															<option value='1'>All</option>
															<option value='2'>Adds</option>
															<option value='3'>Removes</option>
														</select>
													</div>
												</div>
											</div>
										</div>
										<div class='table-responsive PoolsTrnsTbl'>
											<table class='table table-borderless'>
												<thead>
													<tr>
														<th scope='col'>Pool</th>
														<th scope='col'>Value</th>
														<th scope='col'>Txn Hash</th>
														<th scope='col'>Time</th>
													</tr>
												</thead>
												<tbody>
													{/* <tr>
                                                   <td>QBANK+USDT</td>
                                                   <td>$1032.00</td>
                                                   <td>0x779f....95a6</td>
                                                   <td>Just now</td>
                                                </tr>
                                                <tr>
                                                   <td>QBANK+USDT</td>
                                                   <td>$1032.00</td>
                                                   <td>0x779f....95a6</td>
                                                   <td>3 hours ago</td>
                                                </tr>
                                                <tr>
                                                   <td>QBANK+USDT</td>
                                                   <td>$1032.00</td>
                                                   <td>0x779f....95a6</td>
                                                   <td>13 days ago</td>
                                                </tr>
                                                <tr>
                                                   <td>QBANK+USDT</td>
                                                   <td>$1032.00</td>
                                                   <td>0x779f....95a6</td>
                                                   <td>17 days ago</td>
                                                </tr>
                                                <tr>
                                                   <td>QBANK+USDT</td>
                                                   <td>$1032.00</td>
                                                   <td>0x779f....95a6</td>
                                                   <td>29 days ago</td>
                                                </tr>
                                                <tr>
                                                   <td>QBANK+USDT</td>
                                                   <td>$1032.00</td>
                                                   <td>0x779f....95a6</td>
                                                   <td>33 days ago </td>
                                                </tr>
                                                <tr>
                                                   <td>QBANK+USDT</td>
                                                   <td>$1032.00</td>
                                                   <td>0x779f....95a6</td>
                                                   <td>42 days ago</td>
                                                </tr>
                                                <tr>
                                                   <td>QBANK+USDT</td>
                                                   <td>$1032.00</td>
                                                   <td>0x779f....95a6</td>
                                                   <td>45 days ago</td>
                                                </tr> */}

													<tr>
														<td colspan='4'>
															<div class='NoDataCnt MnHght-298 text-center'>
																<p>No Transactions found</p>
															</div>
														</td>
													</tr>
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<InnerFooter />
			</div>
		</Fragment>
	)
}

export default Liquidity
