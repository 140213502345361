import { useState, useEffect } from 'react'
import { useWeb3React } from '@web3-react/core'
import {
	NoEthereumProviderError,
	UserRejectedRequestError as UserRejectedRequestErrorInjected
} from '@web3-react/injected-connector'
import { UserRejectedRequestError as UserRejectedRequestErrorFrame } from '@web3-react/frame-connector'

import useEagerConnect from './useEagerConnect'
import useInactiveListener from './useInactiveListener'
import { modalService } from '../../components'

const useWeb3 = () => {
	const { active, error, account, library, connector, activate, deactivate } =
		useWeb3React()

	console.log({ active, account, error, connector })

	const [activatingConnector, setActivatingConnector] = useState()

	useEffect(() => {
		if (activatingConnector && activatingConnector === connector) {
			setActivatingConnector(undefined)
		}
	}, [activatingConnector, connector])

	useEffect(() => {
		if (error?.message) {
			getErrorMessage().then(res => console.log(res))
		}
	}, [error?.message])

	// const triedEager = useEagerConnect(connector)

	// useInactiveListener(!triedEager || !!activatingConnector)

	const connect = async connectors => {
		try {
			// await window.ethereum.request({
			// 	method: 'wallet_switchEthereumChain',
			// 	params: [{ chainId: '0x61' }]
			// })
			console.log(connectors, 'connector')
			await activate(connectors)
			modalService.sendMessage('close')
		} catch (err) {
			console.log(err)
			if (
				contains(err.message, [
					'Request of type',
					'already pending for origin',
					'Please wait.'
				])
			) {
				console.log('Metamask request is pending. Complete the request')
			}
			if (err.message === 'User rejected the request.') {
				console.log('Authorize the app to access your Ethereum account.')
			}
		}
	}

	const disconnect = () => deactivate()

	const getErrorMessage = () => {
		return new Promise((resolve, reject) => {
			if (error instanceof NoEthereumProviderError) {
				resolve(
					'No Ethereum browser extension detected, install MetaMask on desktop'
				)
			} else if (
				error instanceof UserRejectedRequestErrorInjected ||
				error instanceof UserRejectedRequestErrorFrame
			) {
				resolve('Authorize the app to access your Ethereum account.')
			}
		})
	}

	const contains = (target, pattern) => {
		let value = 0
		pattern.forEach(word => {
			value = value + target.includes(word)
		})
		return value > 0
	}

	return { connect, disconnect, active, account, getErrorMessage }
}

export default useWeb3
