import ReactReadMoreReadLess from 'react-read-more-read-less'

import swapImg from '../../assets/images/swap.png'
import liqImg from '../../assets/images/liq.png'
import stakeImg from '../../assets/images/stake.png'
import farmImg from '../../assets/images/farm.png'
import tradeImg from '../../assets/images/trade.png'
import lotteryImg from '../../assets/images/lottery.png'

const Services = () => {
	return (
		<section className='sersec pt-5'>
			<h1 data-text='Our services'>Our services</h1>
			<h3>Our services</h3>
			<div className='container'>
				<div className='row OurSerHt'>
					<div className='col-lg-4 col-md-6 serseccol OurSerHt'>
						<div className='servsecdtcnt'>
							<div className='servsecdtcntimgdv'>
								<img src={swapImg} />
							</div>
							<h5>Swap</h5>
							<p>
								<ReactReadMoreReadLess
									charLimit={114}
									readMoreText={'Read More'}
									readLessText={'Read Less'}
								>
									{`Exchange tokens instantly without a middle party with other users on the platform. In a token swap, one party will pay a certain amount of token A to the other party and receive the agreed amount of token B in return.`}
								</ReactReadMoreReadLess>
							</p>
						</div>
					</div>
					<div className='col-lg-4 col-md-6 serseccol OurSerHt'>
						<div className='servsecdtcnt'>
							<div className='servsecdtcntimgdv'>
								<img src={liqImg} />
							</div>
							<h5>Liquidity</h5>
							<p>
								<ReactReadMoreReadLess
									charLimit={114}
									readMoreText={'Read More'}
									readLessText={'Read Less'}
								>
									{`Join liquidity pools with others worldwide, by locking tokens in a smart contract that is used to facilitate trades between the assets on the Quadrix decentralized exchange.`}
								</ReactReadMoreReadLess>
							</p>
						</div>
					</div>
					<div className='col-lg-4 col-md-6 serseccol OurSerHt'>
						<div className='servsecdtcnt'>
							<div className='servsecdtcntimgdv'>
								<img src={stakeImg} />
							</div>
							<h5>Staking</h5>
							<p>
								<ReactReadMoreReadLess
									charLimit={114}
									readMoreText={'Read More'}
									readLessText={'Read Less'}
								>
									{`Staking involves "locking up" a portion of your tokens for some time and in return earning QDRX. Get more out of your tokens while holding and supporting the project’s roadmap by earning QRDX.`}
								</ReactReadMoreReadLess>
							</p>
						</div>
					</div>
					<div className='col-lg-4 col-md-6 serseccol'>
						<div className='servsecdtcnt'>
							<div className='servsecdtcntimgdv'>
								<img src={farmImg} />
							</div>
							<h5>Farming</h5>
							<p>
								<ReactReadMoreReadLess
									charLimit={114}
									readMoreText={'Read More'}
									readLessText={'Read Less'}
								>
									{`Liquidity provider tokens (LP Tokens) are proof that you own a piece of the liquidity pool you stake your tokens in. You need these tokens to redeem your assets when you want to sell your tokens, but until that time you can use your LP Tokens to yield farm.`}
								</ReactReadMoreReadLess>
							</p>
						</div>
					</div>
					<div className='col-lg-4 col-md-6 serseccol'>
						<div className='servsecdtcnt'>
							<div className='servsecdtcntimgdv'>
								<img src={tradeImg} />
							</div>
							<h5>Trade Room</h5>
							<p>
								<ReactReadMoreReadLess
									charLimit={105}
									readMoreText={'Read More'}
									readLessText={'Read Less'}
								>
									{`Finance inventories from approved manufacturers via NFT minting and earn rewards in BUSD. Once you mint your NFT, the minted value is allocated for your chosen trade room. Redeem rewards in BUSD based on the trade room yield structure.`}
								</ReactReadMoreReadLess>
							</p>
						</div>
					</div>
					<div className='col-lg-4 col-md-6 serseccol'>
						<div className='servsecdtcnt'>
							<div className='servsecdtcntimgdv'>
								<img src={lotteryImg} />
							</div>
							<h5>Lottery</h5>
							<p>
								<ReactReadMoreReadLess
									charLimit={114}
									readMoreText={'Read More'}
									readLessText={'Read Less'}
								>
									{`A good way to make stacks of QDRX and have fun! When a lottery jackpot isn't won, the next round's prize pool will increase; the longer it’s been since someone has won the jackpot, the larger the lottery prize pool will be.`}
								</ReactReadMoreReadLess>
							</p>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default Services
