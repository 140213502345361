import bgdhaImg from '../../assets/images/bg_dha.png'
import line1Img from '../../assets/images/line_1.png'
import line2Img from '../../assets/images/line_2.png'
import line3Img from '../../assets/images/line_3.png'
import line4Img from '../../assets/images/line_4.png'
import line5Img from '../../assets/images/line_5.png'
import line6Img from '../../assets/images/line_6.png'
import line7Img from '../../assets/images/line_7.png'
import line8Img from '../../assets/images/line_8.png'

const Tokenomics = () => {
	return (
		<section className='tkmsc'>
			<div className='container container_1'>
				<div className='token_total'>
					<div className='token_cd1_bg'>
						<img className='img-fluid' src={bgdhaImg} alt='' />
					</div>
					<div className='pt-2'>
						<div className='token_mics_cd '>
							<h2 className='mb-0' data-text='TOKENOMICS'>
								TOKENOMICS
							</h2>
							<h3>Tokenomics</h3>
							<div className='d-flex  justify-content-center'>
								<p>
									Quadrix token is the heart of our pool
									finace system.You can buy it ,win it and
									even farm it.
								</p>
							</div>
						</div>
					</div>
					<div className='token_cd2  row justify-content-lg-between justify-content-center '>
						<div className=' col-lg-auto token_cd2_in col-4 text-center mb-2 '>
							<div className='phone_card_res_1'>
								<h4>750,000,000</h4>
								<h5>Max Supply</h5>
							</div>
						</div>
						<div className=' col-lg-auto token_cd2_in col-4  text-center mb-2 '>
							<div className='phone_card_res_1'>
								<h4>30,000,000</h4>
								<h5>Current Supply</h5>
							</div>
						</div>
						<div className=' col-lg-auto col-4 token_cd2_in  text-center mb-2 '>
							<div className='phone_card_res_1'>
								<h4>743,040 QDRX</h4>
								<h5>Daily Burn</h5>
							</div>
						</div>
						<div className=' col-lg-auto  col-4 token_cd2_in text-center mb-2 '>
							<div className='phone_card_res_1'>
								<h4>750,00 QDRX</h4>
								<h5>Burned to Date</h5>
							</div>
						</div>
						<div className=' col-lg-auto col-4 token_cd2_in  text-center mb-2 '>
							<div className='phone_card_res_1'>
								<h4>40 QDRX</h4>
								<h5>Block Reward</h5>
							</div>
						</div>
					</div>
					<div className='line_1_nd'>
						<img className='nd-img_1' src={line1Img} alt='' />
						<img className='nd_img_2' src={line6Img} alt='' />
					</div>
					<div className='line_2_nd'>
						<img className='nd-img_1' src={line2Img} alt='' />
						<img className='nd_img_2' src={line7Img} alt='' />
					</div>
					<div className='line_3_nd'>
						<img className='nd-img_1' src={line3Img} alt='' />
						<img className='nd_img_2' src={line8Img} alt='' />
					</div>
					<div className='line_4_nd'>
						<img className='nd-img_1' src={line4Img} alt='' />
						<img className='nd_img_2' src={line7Img} alt='' />
					</div>
					<div className='line_5_nd'>
						<img className='nd-img_1' src={line5Img} alt='' />
						<img className='nd_img_2' src={line6Img} alt='' />
					</div>
				</div>
			</div>
		</section>
	)
}

export default Tokenomics
