import { DefaultPlayer as Video } from 'react-html5video'
import 'react-html5video/dist/styles.css'
import tickImg from '../../assets/images/tick.png'
import tickmobImg from '../../assets/images/tickmob.png'
import quadrixvid from '../../assets/images/quadrix.mp4';
import quadrixpost from '../../assets/images/quadrixposter.jpg';

const Traderoom = () => {
	return (
		<section className='trdrm'>
			<div className='container'>
				<div className='row'>
					<div className='col-lg-12'>
						<h1 data-text='Traderoom'>Traderoom</h1>
						<h2>Traderoom</h2>
					</div>
				</div>
				<div className='row align-items-center trdrmrw'>
					<div className='col-lg-7'>
						<div className='embytdv'>
							<video controls poster={quadrixpost} autoPlay loop>
								<source src={quadrixvid} type='video/mp4' />
							</video>
						</div>
					</div>
					<div className='col-lg-5'>
						<div className='trdrmdt'>
							<h3>
								Finance your inventories<br></br> with Quadrix Trade Room.
							</h3>
							<p>
								Supply chain friction lock's globally €7.58 trillion in excess
								working capital, and funding this costs €758 billion per annum.
								Quadrix Trade Room removes these friction points that
								significantly inhibit growth.
							</p>
							<div className='row'>
								<div className='col-6'>
									<h5>
										<img src={tickImg} className='trdrmdtg' />
										<img src={tickmobImg} className='trdrmdtgmob' />
										Fast Onboarding
									</h5>
									<h5>
										<img src={tickImg} className='trdrmdtg' />
										<img src={tickmobImg} className='trdrmdtgmob' />
										Tokenize Inventories
									</h5>
								</div>
								<div className='col-6'>
									<h5>
										<img src={tickImg} className='trdrmdtg' />
										<img src={tickmobImg} className='trdrmdtgmob' />
										List Your NFTs
									</h5>
									<h5>
										<img src={tickImg} className='trdrmdtg' />
										<img src={tickmobImg} className='trdrmdtgmob' />
										Receive Funding
									</h5>
								</div>
							</div>
							<button className='btn primary-btn my-4 w-190-h-62 mx-auto'>
								Apply Now
							</button>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default Traderoom
