import { useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'

import { Layout } from '../components'
import {
	Home,
	Swap,
	NftTerms,
	PageNotFound,
	Disclaimer,
	TermsOfService,
	Privacy,
	TradeMark,
	Liquidity
} from '../pages'

const App = () => {
	useEffect(() => {
		document.addEventListener('contextmenu', event =>
			event.preventDefault()
		)
	}, [])

	return (
		<Routes>
			<Route path='/' element={<Layout />}>
				<Route index element={<Home />} />
				<Route path='/swap' element={<Swap />} />
				<Route path='/terms-of-service' element={<TermsOfService />} />
				<Route path='/disclaimer' element={<Disclaimer />} />
				<Route path='/privacy' element={<Privacy />} />
				<Route path='/nft-terms' element={<NftTerms />} />
				<Route path='/trade-mark' element={<TradeMark />} />
				<Route path='/liquidity' element={<Liquidity />} />

				<Route path='*' element={<PageNotFound />} />
			</Route>
		</Routes>
	)
}
export default App
